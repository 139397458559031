//import useState hook to create menu collapse state
import { Link } from "react-router-dom";
import BSLogoDark from "../../Images/bs_logo.svg";
import BSLogoLight from "../../Images/BS_Logo_light.svg";

import SmallBSLogo from "../../Images/binarystate_favicon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

import {
  faAdd,
  faChevronLeft,
  faHome,
  faList,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "./Sidebar.css";
import DarkModeToggle from "../DarkmodeToggle/DarkModeToggle";
import { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import NewChecklistPopup from "../NewChecklistPopup/NewChecklistPopup";

const Sidebar = ({ menuCollapse, setMenuCollapse }) => {
  const shouldBeOpen = () => window.innerWidth > 800;
  const [showSidebar, setShowSidebar] = useState(shouldBeOpen);
  const [showNavbar, setShowNavbar] = useState(false);
  const location = useLocation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setShowSidebar(shouldBeOpen());
    });
  }, []);

  return (
    <>
      {isPopupOpen && <NewChecklistPopup open={true} />}

      {showSidebar ? (
        <div className="header">
          <ProSidebar collapsed={menuCollapse}>
            <SidebarHeader>
              <div className="bs-image">
                {menuCollapse ? (
                  <img
                    src={SmallBSLogo}
                    alt="BS Logo"
                    className="small-logo"
                    style={{ transition: "transform 3000ms" }}
                  />
                ) : (
                  <div>
                    <div alt="BS Logo" className="big-logo" />
                    <div>
                      <h5
                        style={{
                          margin: "20px 25px 0",
                        }}
                      >
                        Checklist-App
                      </h5>
                    </div>
                  </div>
                )}
              </div>
            </SidebarHeader>
            <SidebarContent>
              <Menu>
                <MenuItem
                  className={`link-btn ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faHome}
                    className="menu-icon"
                    style={{ padding: "9px", color: "var(--text-color)" }}
                  ></FontAwesomeIcon>

                  <Link to="/" className="title">
                    Home
                  </Link>
                </MenuItem>
                <MenuItem
                  className={`link-btn ${
                    location.pathname === "/myChecklists" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faList}
                    className="menu-icon"
                    style={{ padding: "9px", color: "var(--text-color)" }}
                  ></FontAwesomeIcon>
                  <Link to="/myChecklists" className="title">
                    Alle Checklisten
                  </Link>
                </MenuItem>
                <MenuItem
                  className={`link-btn ${
                    location.pathname === "/addnewdailychecklistpage" ||
                    location.pathname === "/newchecklist/step1"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => setIsPopupOpen(!isPopupOpen)}
                >
                  <FontAwesomeIcon
                    icon={faAdd}
                    className="menu-icon"
                    style={{ padding: "9px", color: "var(--text-color)" }}
                  ></FontAwesomeIcon>
                  Neue Checkliste
                </MenuItem>
                <MenuItem
                  className={`link-btn ${
                    location.pathname === "/myAccount" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    className="menu-icon"
                    style={{ padding: "9px", color: "var(--text-color)" }}
                  ></FontAwesomeIcon>
                  <Link to="/myAccount" className="title">
                    Mein Konto
                  </Link>
                </MenuItem>
              </Menu>
            </SidebarContent>

            <SidebarFooter
              style={{
                position: "absolute",
                bottom: 0,
                border: "none",
                width: "100%",
                paddingRight: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="darkModeToggleContainer">
                  <DarkModeToggle></DarkModeToggle>
                </div>
                <Menu
                  iconShape="square"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    fontSize: "1rem",
                    marginRight: "10px",
                  }}
                  className="footer"
                  onClick={() => {
                    setMenuCollapse(!menuCollapse);
                  }}
                >
                  {menuCollapse ? (
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="close-sidebar"
                      style={{
                        transform: "rotate(180deg)",
                        transition: "transform 0ms",
                        position: "absolute",
                        left: "21px",
                        background: "var(--second-bg-color)",
                        padding: " 8px 25px",
                        bottom: "10px",
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="open-sidebar"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        // width: "260px",
                        maxHeight: "40px",
                        transition: "transform 300ms",
                      }}
                    />
                  )}
                </Menu>
              </div>
            </SidebarFooter>
          </ProSidebar>
        </div>
      ) : (
        <div className="">
          <button
            className="navBarBtn"
            onClick={() => setShowNavbar(!showNavbar)}
          >
            {showNavbar ? (
              <FaTimes style={{ minWidth: "15px", minHeight: "15px" }} />
            ) : (
              <FaBars style={{ minWidth: "15px", minHeight: "15px" }} />
            )}
          </button>
          <div className={`navbar ${showNavbar ? "open" : ""}`}>
            <nav className="navbarMenu">
              <ul className="navbarMenuItems">
                <li>
                  {" "}
                  <Link
                    to="/"
                    className="title"
                    onClick={() => setShowNavbar(false)}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/myChecklists"
                    className="title"
                    onClick={() => setShowNavbar(false)}
                  >
                    Alle Checklisten
                  </Link>
                </li>
                <li onClick={() => setIsPopupOpen(!isPopupOpen)}>
                  {" "}
                  <div onClick={() => setShowNavbar(false)}>
                    Neue Checkliste
                  </div>
                </li>

                <li>
                  <Link
                    to="/myAccount"
                    className="title"
                    onClick={() => setShowNavbar(false)}
                  >
                    Mein Konto{" "}
                  </Link>
                </li>

                <div className="darkModeToggleContainer">
                  <DarkModeToggle></DarkModeToggle>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;

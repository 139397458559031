import { Routes, Route } from "react-router-dom";
import AddNewCheckpoints from "../Pages/AddNewCheckpoints/AddNewCheckpoints.js";
import ChecklistTemplate from "../Pages/ChecklistTemplate/ChecklistTemplate.js";
import NewChecklist from "../Pages/NewChecklist/NewChecklist.js";
import Overview from "../Pages/Overview/Overview.js";
import Home from "../Pages/Home/Home.js";
import Sidebar from "../Components/Sidebar/Sidebar.js";
import React, { useState, useEffect, createContext } from "react";
import "./Routes.css";
import ChooseNewTemplate from "../Pages/ChooseNewTemplate/ChooseNewTemplate.js";
import Checklist from "../Pages/Checklist/Checklist.js";
import MyChecklists from "../Pages/MyChecklists/MyChecklists";
import EditPage from "../Components/EditPage/EditPage.js";
import ScrollToTop from "../Components/ScrollToTop.js";
import AddNewDailyChecklistPage from "../Pages/DailyChecklist/AddNewDailyChecklistPage.js";
import DailyChecklistPage from "../Pages/DailyChecklist/DailyChecklist.js";
import DailyEditPage from "../Pages/DailyEditPage/DailyEditPage.js";
import Footer from "../Components/Footer/Footer.js";
import MyAccount from "../Pages/MyAccount/MyAccount.js";
export const CheckboxContext = createContext();

function Directions() {
  const shouldBeOpen = () => window.innerWidth < 1200;
  const [menuCollapse, setMenuCollapse] = useState(shouldBeOpen);
  const contentStyle = {
    padding: "10px",
    marginLeft: menuCollapse ? " 90px" : "275px",
    transition: "margin 0.2s ease",
    borderRadius: "30px",
  };

  //Resize Sidebar
  useEffect(() => {
    window.addEventListener("resize", () => {
      setMenuCollapse(shouldBeOpen());
    });
  }, []);

  return (
    <div className="Routes">
      <Sidebar setMenuCollapse={setMenuCollapse} menuCollapse={menuCollapse} />

      <main style={contentStyle}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/newchecklist/:step" element={<NewChecklist />}></Route>
          <Route path="/myChecklists" element={<MyChecklists />}></Route>

          <Route path="/checklisttemplate" element={<ChecklistTemplate />} />
          <Route path="/choosenewtemplate" element={<ChooseNewTemplate />} />
          <Route path="/addnewcheckpoints" element={<AddNewCheckpoints />} />
          <Route path="/checklist" element={<Checklist />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/editpage" element={<EditPage />} />
          <Route path="/dailyeditpage" element={<DailyEditPage />} />

          <Route
            path="/addnewdailychecklistpage"
            element={<AddNewDailyChecklistPage />}
          />
          <Route path="/dailychecklistpage" element={<DailyChecklistPage />} />
          <Route path="/myAccount" element={<MyAccount />} />
        </Routes>
        <Footer></Footer>
      </main>
    </div>
  );
}

export default Directions;

import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SlIcon } from "@shoelace-style/shoelace/dist/react";
import { useState } from "react";
import { FaCog } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import ProgressBar from "../../Components/Progressbar/Progressbar";
import getDailyChecklistArray from "../../utils/dailyChecklistenLocalStorage";
import "./DailyChecklist.css";

function DailyChecklistPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const checklistIdTEST = searchParams.get("checklistId");
  const getData = getDailyChecklistArray();

  const [dataDailyChecklist, setDailyChecklist] = useState(
    getData.find((checklist) => checklist.id === checklistIdTEST)
  );
  const [isChecked, setIsChecked] = useState(
    dataDailyChecklist?.checkpoints.map((obj) => obj.isChecked)
  );

  const handleCheck = (id, parentId) => {
    console.log("id", id);
    console.log("parentId", parentId);
    const updatedData = getData.map((obj) => {
      console.log("obj", obj.id);
      console.log("checklistIdTEST", checklistIdTEST);

      if (obj.id !== checklistIdTEST) {
        return obj;
      }

      const updatedCheckpoints = obj.checkpoints.map((checkID) => {
        console.log("checkID.checkpointsId", checkID.checkpointsId);
        console.log("id", id);
        if (checkID.checkpointsId === id) {
          checkID.isChecked = !checkID.isChecked;

          if (checkID.subItem) {
            checkID.subItem.forEach((subItems) => {
              subItems.isChecked = checkID.isChecked;
            });
          }
        } else if (checkID.checkpointsId === parentId) {
          checkID.isChecked = !checkID.isChecked;

          if (checkID.subItem) {
            checkID.subItem.forEach((subItems) => {
              subItems.isChecked = false;
            });
          }
        } else if (checkID.subItem) {
          const updatedSubItems = checkID.subItem.map((subItems) => {
            if (subItems.checkpointsId === id) {
              subItems.isChecked = !subItems.isChecked;
            }
            return subItems;
          });
          checkID.isChecked = updatedSubItems.every(
            (subItem) => subItem.isChecked
          );
          checkID.subItem = updatedSubItems;
        }
        return checkID;
      });
      console.log("updatedCheckpoints", updatedCheckpoints);

      return {
        ...obj,
        checkpoints: updatedCheckpoints,
      };
    });
    console.log("updatedData", updatedData);

    localStorage.setItem("DailyChecklistArray", JSON.stringify(updatedData));

    const updatedChecklist = updatedData.find(
      (checklist) => checklist.id === checklistIdTEST
    );

    setDailyChecklist(updatedChecklist);

    // Update isChecked state
    setIsChecked(
      updatedChecklist?.checkpoints.map((obj) => obj.isChecked) || []
    );
  };

  let countIsChecked = 0;
  let countCheckpoints = dataDailyChecklist.checkpoints.length;
  let numbinPercent = 0;
  dataDailyChecklist.checkpoints.map((checkpoint) => {
    checkpoint.isChecked === true && countIsChecked++;
  });
  numbinPercent = (100 * countIsChecked) / countCheckpoints;

  function translate(str) {
    console.log(str);
    if (str === "daily") return "Täglich";
    if (str === "weekly") return "Wöchentlich";
    if (str === "everyTwoWeeks") return "Alle zwei Wochen";
    if (str === "monthly") return "Monatlich";
    if (str === "yearly") return "Jährlich";

    return "Niemals";
  }

  return (
    <div className="DailyChecklistPage">
      <div className="mainContainer">
        <div className="DailyChecklistPageContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1 className="title">{dataDailyChecklist.title}</h1>
            <div className="edit">
              <Link
                to={`/dailyeditpage?showTakenChecklistId=${dataDailyChecklist.id}`}
                style={{ textDecoration: "none" }}
              >
                <FaCog
                  style={{ width: "30px", height: "30px", color: "#BC6B2E" }}
                ></FaCog>
              </Link>
            </div>
          </div>
          <p className="description">{dataDailyChecklist.description}</p>
        </div>

        <div
          className="dateContainer"
          style={{
            display: "flex",
            alignItems: "center",
            margin: "20px 64px",
          }}
        >
          <FontAwesomeIcon
            icon={faCalendarDays}
            style={{
              color: "#BC6B2E",
              marginRight: "10px",
              minWidth: "25px",
              minHeight: "25px",
            }}
          ></FontAwesomeIcon>

          <div className="DailyChecklistPageDate">
            {new Date(dataDailyChecklist.date).toLocaleString("de-Eu", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
          </div>

          <SlIcon name="repeat" className="repeatIcon"></SlIcon>
          <div className="DailyChecklistPageDate">
            {translate(dataDailyChecklist.repeats)}
          </div>
        </div>

        <ul className="DailyChecklistUl">
          {dataDailyChecklist.checkpoints.map((obj) => {
            return (
              <li key={obj.id}>
                <div className="ListContainer"></div>
                <div className="line"></div>
                <div className="check-list-item">
                  <div className="left-section">
                    <input
                      type="checkbox"
                      className="checkobox-square"
                      checked={obj.isChecked}
                      onChange={() =>
                        handleCheck(
                          obj.checkpointsId,
                          dataDailyChecklist.id,
                          dataDailyChecklist.date
                        )
                      }
                    />

                    <label
                      className="checkboxdefault"
                      htmlFor={`custom-checkbox-${obj.checkpointsId}`}
                    >
                      {obj.content}
                    </label>
                  </div>
                </div>
                <ul>
                  {obj.subItem?.map((sub) => {
                    return (
                      <div className="SubListContainer" key={sub.id}>
                        <li style={{ listStyle: "none" }}>
                          <div className="ListContainer2"></div>
                          <div className="line"></div>
                          <div className="check-list-item">
                            <div className="sub-left-section ">
                              <input
                                type="checkbox"
                                className="checkobox-square"
                                checked={sub.isChecked}
                                onChange={() =>
                                  handleCheck(sub.checkpointsId, obj.id)
                                }
                              />

                              <label
                                className="checkboxdefault"
                                htmlFor={`custom-checkbox-${sub.checkpointsId}`}
                              >
                                {sub.content}
                              </label>
                            </div>
                          </div>
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
        <ProgressBar
          completed={numbinPercent.toFixed(2)}
          num={countIsChecked}
          anzahlNum={countCheckpoints}
        ></ProgressBar>
      </div>
    </div>
  );
}

export default DailyChecklistPage;

import { useState, useEffect } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";
import "./DayPicker.css";
import { FaTimes } from "react-icons/fa";

function DayPickerComponent({
  pickedDay,
  showDayPickerTrue,
  onSelectedDayChange,
}) {
  const [showDayPicker, setShowDayPicker] = useState(showDayPickerTrue);
  const [selectedDay, setSelectedDay] = useState(pickedDay || new Date());
  const [shouldClose, setShouldClose] = useState(false);

  useEffect(() => {
    if (shouldClose && selectedDay) {
      onSelectedDayChange(selectedDay);
      setShowDayPicker(false);
    }
  }, [shouldClose, selectedDay, onSelectedDayChange]);

  let footer = <p>Please pick a day.</p>;
  if (selectedDay) {
    footer = (
      <div className="footerContainer">
        {" "}
        <p>You picked {format(selectedDay, "PP")}.</p>
        <div className="close-popup">
          <FaTimes
            className="close-icon-dayPicker"
            onClick={() => {
              setShowDayPicker(false); // Schließe den DayPickerComponent
              setShouldClose(false); // Setze shouldClose zurück
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="DayPickerContainer">
      {showDayPicker && (
        <div className="DayPickerContainerPopup">
          <DayPicker
            mode="single"
            selected={selectedDay}
            onSelect={(day) => {
              setSelectedDay(day);
              setShouldClose(true);
            }}
            footer={footer}
          />
        </div>
      )}
    </div>
  );
}

export default DayPickerComponent;

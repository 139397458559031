import React, { useState, useEffect } from "react";
import { FaCog } from "react-icons/fa";
import { Link } from "react-router-dom";
import getInputArray from "../../utils/checklistenLocalStorage";
import "../Checkboxes/Checkboxes.css";
import ProgressBar from "../Progressbar/Progressbar";
import SortDropDown from "../SortDropDown/SortDropDown";

function Checkboxes({ checklistContent }) {
  const [selectedItem, setSelectedItem] = useState([]);
  const data = getInputArray();
  const [showTakenChecklist, setShowTakenChecklist] = useState(
    data.find((checklist) => checklist.id === checklistContent)
  );
  const [isChecked, setIsChecked] = useState([]);

  const onItemSelected = (item) => {
    console.log("ITEM", item);
    setSelectedItem(item);
  };

  useEffect(() => {
    // Set initial checked state when showTakenChecklist changes
    setIsChecked(
      showTakenChecklist.checkpoints.map((obj) => obj.isChecked) || []
    );
  }, [showTakenChecklist]);

  const handleCheck = (id, parentId) => {
    const updatedData = data.map((obj) => {
      if (obj.id !== checklistContent) {
        return obj;
      }

      const updatedCheckpoints = obj.checkpoints.map((checkID) => {
        if (checkID.checkpointsId === id) {
          checkID.isChecked = !checkID.isChecked;

          if (checkID.subItems) {
            checkID.subItems.forEach((subItem) => {
              subItem.isChecked = checkID.isChecked;
            });
          }
        } else if (checkID.checkpointsId === parentId) {
          checkID.isChecked = !checkID.isChecked;

          if (checkID.subItems) {
            checkID.subItems.forEach((subItem) => {
              subItem.isChecked = false;
            });
          }
        } else if (checkID.subItems) {
          const updatedSubItems = checkID.subItems.map((subItem) => {
            if (subItem.checkpointsId === id) {
              subItem.isChecked = !subItem.isChecked;
            }
            return subItem;
          });
          checkID.isChecked = updatedSubItems.every(
            (subItem) => subItem.isChecked
          );
          checkID.subItems = updatedSubItems;
        }
        return checkID;
      });

      return {
        ...obj,
        checkpoints: updatedCheckpoints,
      };
    });

    localStorage.setItem("InputArray", JSON.stringify(updatedData));

    const updatedChecklist = updatedData.find(
      (checklist) => checklist.id === checklistContent
    );

    setShowTakenChecklist(updatedChecklist);

    // Update isChecked state
    setIsChecked(
      updatedChecklist.checkpoints.map((obj) => obj.isChecked) || []
    );
  };

  let numbIsChecked = 0;
  showTakenChecklist.checkpoints.forEach((checked) => {
    if (checked.isChecked === true) {
      numbIsChecked += 1;
    }
  });

  let numbinPercent =
    (100 * numbIsChecked) / (showTakenChecklist.checkpoints.length || 1);

  if (selectedItem === "Erledigt") {
    showTakenChecklist.checkpoints.sort(done);
  } else if (selectedItem === "Nicht Erledigt") {
    showTakenChecklist.checkpoints.sort(notDone);
  } else if (selectedItem === "Alphabetisch") {
    showTakenChecklist.checkpoints.sort(alphabetic);
  }
  function alphabetic(a, b) {
    console.log("alphabetic", a, b);
    if (a.content < b.content) {
      return -1;
    }
    if (a.content > b.content) {
      return 1;
    }

    return 0;
  }
  console.log("showTakenChecklist.checkpoints", showTakenChecklist.checkpoints);

  function done(a, b) {
    if (a.isChecked > b.isChecked) {
      return -1;
    }
    if (a.isChecked < b.isChecked) {
      return 1;
    }
    return 0;
  }

  function notDone(a, b) {
    if (a.isChecked < b.isChecked) {
      return -1;
    }
    if (a.isChecked > b.isChecked) {
      return 1;
    }
    return 0;
  }

  let countIsChecked = 0;
  let countCheckpoints = showTakenChecklist.checkpoints.length;

  showTakenChecklist.checkpoints.map(
    (checked) => checked.isChecked === true && countIsChecked++
  );
  numbinPercent =
    (100 * countIsChecked) / showTakenChecklist.checkpoints.length;
  console.log("countIsChecked", countIsChecked);
  console.log("countCheckpoints", countCheckpoints);

  return (
    <div className="Checkboxes">
      <div key={showTakenChecklist.id}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "0px 25px 0 0px",
            }}
          >
            <h1 className="title">{showTakenChecklist.title}</h1>

            <div className="edit">
              <Link
                to={`/editpage?showTakenChecklistId=${showTakenChecklist.id}`}
                style={{ textDecoration: "none" }}
              >
                <FaCog
                  style={{ width: "30px", height: "30px", color: "#BC6B2E" }}
                ></FaCog>
              </Link>
            </div>
          </div>

          <div className="sort-dropdown-container">
            <div className="CheckboxesHeaderContainer">
              <div className="description">
                {showTakenChecklist.description}
              </div>
            </div>
            <SortDropDown
              sortItem={selectedItem}
              item={[
                "Zuletzt hinzugefügt",
                "Erledigt",
                "Nicht Erledigt",
                "Alphabetisch",
              ]}
              onItemSelected={onItemSelected}
              value={selectedItem}
              className="SortDropDown"
            ></SortDropDown>
          </div>
        </div>

        <ul className="checkbox-list">
          {showTakenChecklist.checkpoints.map((obj, index) => {
            return (
              <li key={obj.id}>
                <div className="ListContainer"></div>
                <div className="line"></div>
                <div className="check-list-item">
                  <div className="left-section">
                    <input
                      type="checkbox"
                      className="checkobox-square"
                      checked={obj.isChecked}
                      onChange={() =>
                        handleCheck(obj.checkpointsId, obj.parentId)
                      }
                    />

                    <label
                      className="checkboxdefault"
                      htmlFor={`custom-checkbox-${obj.checkpointsId}`}
                    >
                      {obj.content}
                    </label>
                  </div>
                  {obj.subItems?.map((sub) => {
                    return (
                      <div className="SubListContainer" key={sub.id}>
                        <li>
                          <div className="ListContainer2"></div>
                          <div className="line"></div>
                          <div className="check-list-item">
                            <div className="sub-left-section ">
                              <input
                                type="checkbox"
                                className="checkobox-square"
                                checked={sub.isChecked}
                                onChange={() =>
                                  handleCheck(sub.checkpointsId, obj.id)
                                }
                              />

                              <label
                                className="checkboxdefault"
                                htmlFor={`custom-checkbox-${sub.checkpointsId}`}
                              >
                                {sub.content}
                              </label>
                            </div>
                          </div>
                        </li>
                      </div>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>
        <div className="ProgressbarContainer">
          <ProgressBar
            completed={numbinPercent.toFixed(2)}
            num={countIsChecked}
            anzahlNum={countCheckpoints}
          ></ProgressBar>
        </div>
      </div>
    </div>
  );
}

export default Checkboxes;

import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./DailyChecklistContainer.css";
import { Link } from "react-router-dom";
import getDailyChecklistArray from "../../utils/dailyChecklistenLocalStorage";
import { SlIcon } from "@shoelace-style/shoelace/dist/react";
import DailyChecklistHomeContainer from "../DailyChecklistHomeContainer/DailyChecklistHomeContainer";
import AddNewDailyChecklistPage from "../../Pages/DailyChecklist/AddNewDailyChecklistPage";

function DailyChecklistContainer() {
  const [date, setDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dayInput, setDayInput] = useState("");

  const [showDataListViaDay, setshowDataListViaDay] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const dailyData = getDailyChecklistArray();

  useEffect(() => {
    const filteredData = dailyData.filter(
      (obj) => obj.date === date.toDateString()
    );
    setshowDataListViaDay(filteredData);
  }, [date]);

  const handleDayClick = (repeatValue) => {
    console.log("TEST", date);
    setSelectedDay(!selectedDay);
    setInputValue(date.toDateString());
  };

  const areAllCheckpointsChecked = (checklist) => {
    return checklist.checkpoints.every((checkpoints) => checkpoints.isChecked);
  };

  const handleDateChange = (event) => {
    const newDate = new Date(event.target.value);
    if (!isNaN(newDate.getTime())) {
      setDate(newDate);
    }
  };

  return (
    <div className="DailyChecklist">
      {isPopupOpen && (
        <AddNewDailyChecklistPage
          open={true}
          pickedDay={date}
        ></AddNewDailyChecklistPage>
      )}
      <div className="dailyChecklistContainer">
        <div className="dailyCheckpointsContainer">
          <div>
            <div className="ShowDateND">
              <span className="date">Datum</span>
              <input
                type="date"
                onChange={handleDateChange}
                className="dateInput"
                value={date.toISOString().split("T")[0]} // Formatierung auf TT/MM/YYYY
              ></input>
            </div>
          </div>
          <div className="DailyChecklistCalenderContainerNewDesign">
            <Calendar
              onChange={setDate}
              value={date}
              onClickDay={handleDayClick}
              tileContent={({ date, view }) => {
                if (view === "month") {
                  const hasChecklist = dailyData.some(
                    (obj) => obj.date === date.toDateString()
                  );
                  return hasChecklist ? (
                    <div className="date-marker"></div>
                  ) : null;
                }
              }}
            ></Calendar>
          </div>
        </div>
        {console.log("showDataListViaDay", showDataListViaDay)}
        <div className="dailyCheckpointsContainer">
          <div className="dailyCheckpointsList">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 45px 0 0",
              }}
            >
              <h4 style={{ margin: "0px" }}>Tagescheckliste</h4>
              <SlIcon
                name="plus"
                style={{
                  color: "#bc6b2e",
                  width: "30px",
                  height: "30px",
                  margin: "5px",
                }}
                onClick={() => setIsPopupOpen(!isPopupOpen)}
              ></SlIcon>
            </div>

            <DailyChecklistHomeContainer
              date={date}
            ></DailyChecklistHomeContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailyChecklistContainer;

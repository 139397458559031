import React from "react";
import { NavLink } from "react-router-dom";
import NextPageButton from "./NextPageButton";

function StepIndicator(props) {
  const steps = [
    {
      path: "/newchecklist/step1",
      label: "Checkliste erstellen",
    },
    {
      path: "/newchecklist/step2",
      label: "Vorlage wählen",
    },
    {
      path: "/newchecklist/step3",
      label: "Checkpoints hinzufügen",
    },
    {
      path: "/newchecklist/step4",
      label: "Übersicht",
    },
  ];

  const handleStop = (e) => {
    e.preventDefault();
  };
  return (
    <div className="stepIndicator">
      <div classname="stepIndicatorMargin"></div>

      {steps.map((step, index) => {
        return (
          <>
            <div className="stepIndicatorContainer">
              <div className="iconTest">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "activeLink" : "none"
                  }
                  to={step.path}
                  onClick={handleStop}
                >
                  {index + 1}
                </NavLink>
              </div>

              <div className="labelTest">{step.label}</div>
            </div>
            <hr />
          </>
        );
      })}
    </div>
  );
}

export default StepIndicator;

export const checkpointsVacation = [
  {
    templateId: "urlaubcheckliste",
    templateTitle: "Urlaubcheckliste",
    description:
      "Liste von Aufgaben, die ich vor meinen Ulaub zu erledigen habe bzw. an jemand anderen zuteilen muss",
    checkpoints: [
      {
        checkpointsId: "vac1",
        content: "Arbeitsaufgaben delegieren",
        isChecked: false,
      },
      {
        checkpointsId: "vac2",
        content: "Abwesendheitsnotiz an Kollegen und Vorgesetzte senden",
        isChecked: false,
      },
      {
        checkpointsId: "vac3",
        content: "Projektstatus und offene Aufgaben akutalisieren",
        isChecked: false,
      },

      {
        checkpointsId: "vac4",
        content: "E-Mails und Posteingang aufräumen",
        isChecked: false,
      },
      {
        checkpointsId: "vac5",
        content: "Arbeitsplatz sauber und ordentlich hinterlassen",
        isChecked: false,
      },
      {
        checkpointsId: "vac6",
        content:
          "Abonnements, Lieferungen oder regelmäßige Dienstleistungen pausieren oder weiterleiten lassen",
        isChecked: false,
      },

      {
        checkpointsId: "vac7",
        content:
          "Sicherungskopien von wichtigen Dateien und Dokumenten erstellen",
        isChecked: false,
      },
      {
        checkpointsId: "vac8",
        content:
          "Laufende Projekte und Arbeitsfortschritte an Teammitglieder kommunizieren",
        isChecked: false,
      },
      {
        checkpointsId: "vac9",
        content: "Autoresponder für geschäftliche E-Mails einrichten",
        isChecked: false,
      },
      {
        checkpointsId: "vac10",
        content: "Den Urlaub genießen und sich erholen",
        isChecked: false,
      },
    ],
  },
];

import { FaRegHeart, FaHeart, FaEllipsisV } from "react-icons/fa";
import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import getInputArray from "../../utils/checklistenLocalStorage";
import "./ChecklistContainer.css";
import {
  SlDivider,
  SlIcon,
  SlMenu,
  SlMenuItem,
} from "@shoelace-style/shoelace/dist/react";
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path.js";
import getDailyChecklistArray from "../../utils/dailyChecklistenLocalStorage";

setBasePath(
  "https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.4.0/dist/"
);

function ShowDailyChecklistContainer({
  dataUId,
  isFav,
  toggleFavorite,
  showSlMenu,
  isTrue,
  handleDeleteChecklist,
}) {
  const checklistToLike = getDailyChecklistArray().find(
    (obj) => obj.id === dataUId
  );
  const numb = checklistToLike?.checkpoints?.length || 0;

  const navigate = useNavigate();
  const contextMenu = useRef(null);

  let count = 0;
  checklistToLike?.checkpoints?.forEach((obj) => {
    if (obj.isChecked) {
      count += 1;
    }
  });

  const handleChange = () => {
    toggleFavorite(dataUId);
  };

  const handleShowContext = (e) => {
    e.preventDefault();
    console.log(e);
    console.log(contextMenu);

    showSlMenu(dataUId);
    contextMenu.current.style.top = e.y + "px";
    contextMenu.current.style.left = e.x + "px";
  };

  if (!checklistToLike) {
    return null;
  }

  console.log(checklistToLike);

  return (
    <>
      <div className="ChecklistContainer">
        <SlMenu
          ref={contextMenu}
          style={{
            display: isTrue ? "block" : "none",
            position: "absolute",
            background: "rgba(35, 40, 44, 0.9)",
            border: "1px solid #FFFFFF",
            borderRadius: "18px",
            color: "white",
            width: "200px",
          }}
        >
          <div className="ContextMenuTitle">Checkliste...</div>

          <SlDivider
            style={{
              "--color": "white",
              "--width": "2px",
              margin: "15px",
            }}
          />

          <Link
            to={`/dailyeditpage?showTakenChecklistId=${checklistToLike.id}`}
            style={{ textDecoration: "none", color: "white" }}
            className="ContextMenuItem"
          >
            <SlIcon name="pencil-square" className="ContextMenuIcon"></SlIcon>
            <SlMenuItem value="cut" className="ContextMenuText">
              bearbeiten
            </SlMenuItem>
          </Link>
          <div className="ContextMenuItem" onClick={handleDeleteChecklist}>
            <SlIcon name="trash" className="ContextMenuIcon"></SlIcon>
            <SlMenuItem value="cut" className="ContextMenuText">
              löschen
            </SlMenuItem>
          </div>
        </SlMenu>
        <span className="iconContainer">
          {checklistToLike.isFavourite ? (
            <FaHeart className="icon " />
          ) : (
            <FaRegHeart className="icon f-right" />
          )}
          <FaEllipsisV
            className="icon"
            style={{ cursor: "context-menu" }}
            onClick={handleShowContext}
          />
        </span>
        <div
          className="linkContainer"
          onClick={() => {
            navigate(`/dailychecklistpage?checklistId=${dataUId}`);
          }}
        >
          <div className="infos f-row">
            <h4 class="title">{checklistToLike.title}</h4>
            <span class="progress">
              {count}/{numb}
            </span>
          </div>
          <div>
            <p className="infosdate">{checklistToLike.date}</p>
          </div>
          <div className="preview f-column">
            <ul style={{ padding: "0" }}>
              <li style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  readOnly
                  className="checkobox-square"
                  checked={false}
                />
                <label className="checkboxdefault">
                  {checklistToLike?.checkpoints[0]?.content ?? "Checkpoint"}
                </label>
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  className="checkobox-square"
                  checked={false}
                />

                <label className="checkboxdefault">
                  {checklistToLike?.checkpoints[1]?.content ?? "Checkpoint"}
                </label>
              </li>
            </ul>
          </div>
          {checklistToLike.repeats !== "never" && (
            <div className="repeat-icon">
              <SlIcon
                name="repeat"
                className="ContextMenuIcon"
                style={{ color: "var(--primary-color)", margin: "0 10px 0 0" }}
              ></SlIcon>
            </div>
          )}
        </div>
        {/* Context Menu */}
      </div>
    </>
  );
}

export default ShowDailyChecklistContainer;

import React, { useRef, useState } from "react";
import { FaCheck, FaEdit, FaPlus, FaSort, FaTrash } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import getInputArray from "../../utils/checklistenLocalStorage";
import Dialog from "../Dialog/Dialog.jsx";
import "../EditPage/EditPage.css";

function EditPage() {
  const [addItem, setAddItem] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");

  let [checklistData, setChecklistData] = useState(getInputArray());
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search); //new URLSearchParams(location.search);
  const showTakenChecklistId = searchParams.get("showTakenChecklistId"); //searchParams.get("showTakenChecklistId");
  let [showTakenChecklist, setShowTakenChecklist] = useState([]);
  const [showAddSubItemId, setShowAddSubItemId] = useState(false);
  const [addSubItem, setAddSubItem] = useState("");

  showTakenChecklist = checklistData.find(
    (obj) => obj.id === showTakenChecklistId
  );
  const [dialog, setDialog] = useState({
    message: "",
    checklistTitle: "",
    isLoading: false,
    naviTrue: "/",
    naviFalse: `/checklist?checklistId=${showTakenChecklistId}`,
  });

  const [editCheckpoint, setEditCheckpoint] = useState(
    showTakenChecklist.checkpoints.map((obj) => obj.checkpointId)
  );

  const handleDialog = (
    message,
    isLoading,
    checklistTitle,
    navigaTrue,
    navigaFalse
  ) => {
    setDialog({
      message,
      isLoading,
      //Update
      checklistTitle,
      navigaTrue,
      navigaFalse,
    });
  };

  const handleTitleChange = (event) => {
    setEditTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setEditDescription(event.target.value);
  };
  const handleCheckpointChange = (id, value) => {
    setEditCheckpoint((prevState) => ({
      ...prevState,
      [id]: value.charAt(0).toUpperCase() + value.slice(1),
    }));
  };

  //Speichert den Input vom User
  const handleAddItem = (event) => {
    if (addItem.length > 0 && event.code === "Enter") {
      const newItem = {
        checkpointsId: Math.floor(1000 + Math.random() * 9000),
        content: addItem.charAt(0).toUpperCase() + addItem.slice(1),
        isChecked: false,
      };

      showTakenChecklist.checkpoints.unshift(newItem);
      setChecklistData([...checklistData]);
      setAddItem("");
    }
  };

  //Delete checkpoint of checklist
  const handleDelete = (checkpointId) => {
    const updateCheckpoints = showTakenChecklist.checkpoints.filter(
      (checkpoint) => checkpoint.checkpointsId !== checkpointId
    );
    showTakenChecklist.checkpoints = updateCheckpoints;
    setChecklistData([...checklistData]);
  };

  const handleDeleteSubItem = (checkpointId) => {
    const updatedChecklistData = { ...showTakenChecklist };

    updatedChecklistData.checkpoints.forEach((checkpoint) => {
      if (checkpoint.subItems) {
        const updatedSubItems = checkpoint.subItems.filter(
          (subCheckpoint) => subCheckpoint.checkpointsId !== checkpointId
        );
        checkpoint.subItems = updatedSubItems;
      }
    });

    setChecklistData([updatedChecklistData]);
  };

  //Save Edit Input
  const handleSaveEdit = () => {
    const updateChecklistData = checklistData.map((checklist) =>
      checklist.id === showTakenChecklistId ? showTakenChecklist : checklist
    );
    editTitle.length > 0 && (showTakenChecklist.title = editTitle);
    editDescription.length > 0 &&
      (showTakenChecklist.description = editDescription);

    showTakenChecklist.checkpoints.map((check) =>
      editCheckpoint[check.checkpointsId]
        ? (check.content = editCheckpoint[check.checkpointsId])
        : check.content
    );
    editCheckpoint.length > 0 && setChecklistData([...checklistData]);
    setChecklistData(updateChecklistData);
    localStorage.setItem("InputArray", JSON.stringify(updateChecklistData));
  };
  //Deletes complete checklist
  const handleDeleteChecklist = () => {
    setDialog({
      message: "Bist du dir sicher, dass du diese Checkliste löschen möchtest?",
      checklistTitle: showTakenChecklist.title,
      isLoading: true,
      naviTrue: "/",
      naviFalse: `/editpage?showTakenChecklistId=${showTakenChecklistId}`,
    });
  };

  const areUSureDelete = (choose) => {
    if (choose) {
      const deleteChecklist = checklistData.filter(
        (checklist) => checklist.id !== showTakenChecklistId
      );
      checklistData = deleteChecklist;
      localStorage.setItem("InputArray", JSON.stringify(deleteChecklist));
      setChecklistData([...checklistData]);
    } else {
      handleDialog("", false);
    }
  };

  const dragItem = useState([]);
  const dragOverItem = useState([]);

  const handleSort = () => {
    //dublicate items
    let _item = [...showTakenChecklist.checkpoints];

    //remove and save dragged Item content
    const [draggedItemContent] = _item.splice(dragItem.current, 1);

    //switch the position
    _item.splice(dragOverItem.current, 0, draggedItemContent);
    //reset the position
    dragItem.current = null;
    dragOverItem.current = null;

    //update the actual array
    showTakenChecklist.checkpoints = _item;
    setChecklistData([...checklistData]);
  };

  const handleShowAddSubItem = (id) => {
    setShowAddSubItemId(id);
  };

  const handleAddSubItem = (event, checkpointId) => {
    if (addSubItem.length > 0 && event.code === "Enter") {
      const updatedCheckpointsItem = showTakenChecklist.checkpoints.map(
        (checkpoint) => {
          console.log("checkpoint1", checkpoint);
          console.log("checkpoint2", checkpoint.checkpointsId);
          console.log("checkpoint3", checkpointId);

          if (checkpoint.checkpointsId === checkpointId) {
            const newSubItem = {
              checkpointsId: Math.floor(100 + Math.random() * 900),
              content: addSubItem.charAt(0).toUpperCase() + addSubItem.slice(1),
              isChecked: false,
            };
            console.log("newSubItem", newSubItem);
            const updatedSubItems = checkpoint.subItems
              ? [newSubItem, ...checkpoint.subItems]
              : [newSubItem];

            return {
              ...checkpoint,
              subItems: updatedSubItems,
            };
          }
          console.log("checkpoint", checkpoint);

          return checkpoint; // Rückgabe des unveränderten Checkpoints
        }
      );
      console.log("updatedCheckpointsItem", updatedCheckpointsItem);

      showTakenChecklist.checkpoints = updatedCheckpointsItem;
      setChecklistData([...checklistData]);
      setAddSubItem("");
    }
  };

  return (
    <div className="EditPage">
      <div className="EditCheckboxes">
        <div key={showTakenChecklist.id}>
          <div className="headerContainer">
            <Link
              to={`/checklist?checklistId=${showTakenChecklistId}`}
              style={{ color: "#BC6B2E" }}
            >
              <div className="save">
                <FaCheck
                  onClick={handleSaveEdit}
                  style={{
                    minHeight: "20px",
                    minWidth: "20px",
                    color: "rgb(188, 107, 46) ",
                  }}
                ></FaCheck>
              </div>
            </Link>
            <Link
              to={`/checklist?checklistId=${showTakenChecklistId}`}
              style={{ width: "30px", height: "30px", color: "black" }}
            >
              <div className="notSave">
                <FaPlus
                  style={{
                    rotate: "45deg",
                    minHeight: "20px",
                    minWidth: "20px",
                  }}
                ></FaPlus>
              </div>
            </Link>
          </div>
          <div className="container">
            <strong>Titel</strong>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaEdit
                style={{
                  minHeight: "20px",
                  minWidth: "20px",
                }}
              ></FaEdit>
              <input
                className="headerInput"
                id="title"
                key="title"
                type="text"
                value={editTitle || showTakenChecklist.title}
                onChange={handleTitleChange}
              ></input>
            </div>
            <br />
            <strong>Beschreibung</strong>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaEdit
                style={{
                  minHeight: "20px",
                  minWidth: "20px",
                }}
              ></FaEdit>
              <textarea
                id="description"
                key="description"
                className="headerInput"
                type="text"
                placeholder="Füge eine Beschreibung hinzu"
                defaultValue={showTakenChecklist.description}
                value={editDescription || showTakenChecklist.description}
                onChange={handleDescriptionChange}
              ></textarea>
            </div>
          </div>

          <div
            className="innerContainer"
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaPlus style={{ minHeight: "15px", minWidth: "15px" }}></FaPlus>
            <input
              className="input"
              placeholder="Füge eigene Checkpunkte hinzu und drück Enter um die Änderung zu übernehmen"
              value={addItem}
              onKeyDown={handleAddItem}
              onChange={(e) => setAddItem(e.target.value)}
            ></input>
          </div>
          <div className="innerContainer">
            <ul className="checkbox-list">
              {showTakenChecklist.checkpoints.map((obj, index) => {
                return (
                  <li
                    className="li-item"
                    key={obj.id}
                    draggable
                    onDragStart={() => (dragItem.current = index)}
                    onDragEnter={() => (dragOverItem.current = index)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <div className="ListContainer"></div>
                    <div className="line"></div>
                    <div className="check-list-item">
                      <div className="left-section">
                        <FaTrash
                          onClick={() => handleDelete(obj.checkpointsId)}
                          style={{
                            minHeight: "20px",
                            minWidth: "20px",
                            color: "#BC6B2E",
                            cursor: "pointer",
                          }}
                        ></FaTrash>
                        <input
                          className="headerInput"
                          id="checkpoint"
                          key="checkpoint"
                          type="text"
                          value={
                            editCheckpoint[obj.checkpointsId]
                              ? editCheckpoint[obj.checkpointsId]
                              : obj.content
                          }
                          onChange={(e) =>
                            handleCheckpointChange(
                              obj.checkpointsId,
                              e.target.value
                            )
                          }
                        ></input>
                        <FaSort
                          style={{
                            minHeight: "20px",
                            minWidth: "20px",
                            color: "#BC6B2E",
                            margin: "0 10px",
                            cursor: "move",
                          }}
                        ></FaSort>
                        <button
                          style={{ background: "none", border: "none" }}
                          onClick={() =>
                            handleShowAddSubItem(obj.checkpointsId)
                          }
                        >
                          <FaPlus
                            style={{ minHeight: "15px", minWidth: "15px" }}
                          ></FaPlus>
                        </button>
                      </div>
                      {showAddSubItemId === obj.checkpointsId && (
                        <div className="subItemInput">
                          <FaPlus
                            style={{ height: "15px", width: "15px" }}
                          ></FaPlus>

                          <input
                            placeholder="Füge Unterpunkte hinzu"
                            className="subInput"
                            value={addSubItem}
                            onKeyDown={(e) =>
                              handleAddSubItem(e, obj.checkpointsId)
                            }
                            onChange={(e) => setAddSubItem(e.target.value)}
                          />
                        </div>
                      )}
                    </div>
                    <ul className="checkbox-list">
                      {obj.subItems?.map((obj2, index) => {
                        return (
                          <li
                            className="li-item"
                            key={obj2.id}
                            draggable
                            onDragStart={() => (dragItem.current = index)}
                            onDragEnter={() => (dragOverItem.current = index)}
                            onDragEnd={handleSort}
                            onDragOver={(e) => e.preventDefault()}
                          >
                            <div className="ListContainer"></div>
                            <div className="line"></div>
                            <div className="check-list-sub-item">
                              <div className="left-section">
                                <FaTrash
                                  onClick={() =>
                                    handleDeleteSubItem(obj2.checkpointsId)
                                  }
                                  style={{
                                    minHeight: "20px",
                                    minWidth: "20px",
                                    color: "#BC6B2E",
                                    cursor: "pointer",
                                  }}
                                ></FaTrash>
                                <input
                                  className="headerInput"
                                  id="checkpoint"
                                  key="checkpoint"
                                  type="text"
                                  value={
                                    editCheckpoint[obj2.checkpointsId]
                                      ? editCheckpoint[obj2.checkpointsId]
                                      : obj2.content
                                  }
                                  onChange={(e) =>
                                    handleCheckpointChange(
                                      obj2.checkpointsId,
                                      e.target.value
                                    )
                                  }
                                ></input>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="deleteBtnContainer">
            <button className="deleteButton" onClick={handleDeleteChecklist}>
              Checkliste löschen
            </button>
            {dialog.isLoading && (
              <Dialog
                onDialog={areUSureDelete}
                message={dialog.message}
                checklistTitle={dialog.checklistTitle}
                naviTrue={dialog.naviTrue}
                naviFalse={dialog.naviFalse}
                value="Löschen"
                valueTwo="Abbrechen"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPage;

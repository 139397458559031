import { Link } from "react-router-dom";
import "../Dialog/Dialog.css";

function Dialog({
  onDialog,
  message,
  checklistTitle,
  naviTrue,
  naviFalse,
  value,
  valueTwo,
}) {
  return (
    <div className="Dialog">
      <div className="dialogContainer">
        <h3>{message}</h3>
        <h1>{checklistTitle}</h1>
        <div className="btnContainer">
          <Link to={naviTrue}>
            <button className="btnDelete" onClick={() => onDialog(true)}>
              {value}
            </button>
          </Link>
          <Link to={naviFalse}>
            <button className="btnCancel" onClick={() => onDialog(false)}>
              {valueTwo}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Dialog;

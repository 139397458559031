import { useState } from "react";
import ChecklistContainer from "../../Components/ChecklistContainer/ChecklistContainer";
import DailyChecklistContainer from "../../Components/DailyChecklist/DailyChecklistContainer";
import Dialog from "../../Components/Dialog/Dialog";
import NewChecklistPopup from "../../Components/NewChecklistPopup/NewChecklistPopup";
import getInputArray from "../../utils/checklistenLocalStorage";

import "./HomeNewDesign.css";

function Home() {
  const data = getInputArray();
  const [checklists, setChecklists] = useState(data);
  const [showContext, setShowContext] = useState({});
  const favArray = data.filter((obj) => obj.isFavourite === true);

  const [dialog, setDialog] = useState({
    message: "",
    checklistTitle: "",
    isLoading: false,
    naviTrue: "/",
    naviFalse: "/",
  });

  const toggleFavorite = (checklistId) => {
    const updatedChecklists = checklists.map((checklist) => {
      if (checklist.id === checklistId) {
        return { ...checklist, isFavourite: !checklist.isFavourite };
      }
      return checklist;
    });
    setChecklists(updatedChecklists);
    localStorage.setItem("InputArray", JSON.stringify(updatedChecklists));
  };

  const showSlMenu = (dataUId) => {
    setShowContext((prevState) => ({
      ...prevState,
      [dataUId]: !prevState[dataUId],
    }));
  };

  const handleDeleteChecklist = (checklistTitle, checklistId) => {
    setDialog({
      message: "Bist du dir sicher, dass du diese Checkliste löschen möchtest?",
      checklistTitle,
      checklistId,
      isLoading: true,
      naviTrue: "/",
      naviFalse: "/",
    });
  };

  return (
    <div className="Home">
      <div className="HomeContainer">
        <h1>Willkommen auf der Checklist-App!</h1>
        <p style={{ color: "white" }}>
          {" "}
          Ordnung schaffen, Ziele erreichen, Erfolg erleben
        </p>
        <div>
          <DailyChecklistContainer></DailyChecklistContainer>
        </div>
        <div>
          <h2 className="fav1">
            Favoriten{" "}
            <span style={{ fontSize: "18px", font: "caption" }}>
              {favArray.length}
            </span>
          </h2>

          {favArray.length === 0 && (
            <p className="fav2"> Keine Favouriten ausgewählt</p>
          )}
        </div>
        <div className="checklistsContainer">
          {favArray.map((obj) => {
            if (obj.isFavourite === true) {
              return (
                <ChecklistContainer
                  key={obj.id}
                  dataUId={obj.id}
                  isFav={obj.isFavourite}
                  toggleFavorite={toggleFavorite}
                  showSlMenu={showSlMenu}
                  isTrue={showContext[obj.id]}
                  handleDeleteChecklist={() =>
                    handleDeleteChecklist(obj.title, obj.id)
                  }
                ></ChecklistContainer>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default Home;

// import { useState } from "react";
// import ChecklistContainer from "../../Components/ChecklistContainer/ChecklistContainer";
// import DailyChecklistContainer from "../../Components/DailyChecklist/DailyChecklistContainer";
// import Dialog from "../../Components/Dialog/Dialog";
// import getInputArray from "../../utils/checklistenLocalStorage";
// import "./Home.css";

// function Home() {
//   const data = getInputArray();
//   const [checklists, setChecklists] = useState(data);
//   const [showContext, setShowContext] = useState({});
//   const favArray = data.filter((obj) => obj.isFavourite === true);
//   let [checklistData, setChecklistData] = useState(getInputArray());

//   const date = new Date();

// const [dialog, setDialog] = useState({
//   message: "",
//   checklistTitle: "",
//   isLoading: false,
//   naviTrue: "/",
//   naviFalse: "/",
// });

//   const currentTime = date.getHours();

//   let greeting;

//   if (currentTime >= 0 && currentTime <= 12) {
//     greeting = "Guten Morgen ☀️";
//   } else if (currentTime > 12 && currentTime <= 18) {
//     greeting = "Guten Tag 🌼";
//   } else {
//     greeting = "Guten Abend 🌙";
//   }

// const toggleFavorite = (checklistId) => {
//   const updatedChecklists = checklists.map((checklist) => {
//     if (checklist.id === checklistId) {
//       return { ...checklist, isFavourite: !checklist.isFavourite };
//     }
//     return checklist;
//   });
//   setChecklists(updatedChecklists);
//   localStorage.setItem("InputArray", JSON.stringify(updatedChecklists));
// };

// const showSlMenu = (dataUId) => {
//   setShowContext((prevState) => ({
//     ...prevState,
//     [dataUId]: !prevState[dataUId],
//   }));
// };

// const handleDeleteChecklist = (checklistTitle, checklistId) => {
//   setDialog({
//     message: "Bist du dir sicher, dass du diese Checkliste löschen möchtest?",
//     checklistTitle,
//     checklistId,
//     isLoading: true,
//     naviTrue: "/",
//     naviFalse: "/",
//   });
// };

//   const handleDialog = (
//     message,
//     isLoading,
//     checklistTitle,
//     navigaTrue,
//     navigaFalse
//   ) => {
//     setDialog({
//       message,
//       isLoading,
//       //Update
//       checklistTitle,
//       navigaTrue,
//       navigaFalse,
//     });
//   };

//   const areUSureDelete = (choose, dataUId) => {
//     if (choose) {
//       const deleteChecklist = checklistData.filter(
//         (checklist) => checklist.id !== dataUId
//       );
//       checklistData = deleteChecklist;
//       localStorage.setItem("InputArray", JSON.stringify(deleteChecklist));
//       setChecklistData([...checklistData]);
//       handleDialog("", false);
//     } else {
//       handleDialog("", false);
//     }
//   };

//   return (
//     <div className="Home">
//       <div className="mainContainer">
//         <div className="title">
//           <h1>
//             {greeting}
//             <br /> heute ist{" "}
//             {new Date().toLocaleDateString("de-Eu", {
//               weekday: "long",
//             })}
//             {", "}
//             der {new Date().toLocaleString("en-US", { day: "2-digit" })}
//             {". "}
//             {new Date().toLocaleString("de-EU", { month: "long" })}{" "}
//             {new Date().getFullYear()}
//           </h1>
//         </div>
//         <div className="description">
//           <p>
//             Willkommen in deinem persönlichen Checklisten-Paradies!
//             <br />
//             Schaffe Ordnung, erreiche deine Ziele und erlebe den Erfolg einer
//             gut durchdachten Organisation.
//           </p>
//           <p>
//             Wähle zwischen fortlaufenden Checklisten über mehrere Tage hinweg
//             und Tages-Checklisten, um deine Woche effizienter zu strukturieren.
//           </p>
//         </div>
//         <DailyChecklistContainer></DailyChecklistContainer>
//         <div style={{ margin: "0px 45px" }}>
//           <h2 className="fav1">Deine favourisierten Checklisten</h2>

//           {favArray.length === 0 && (
//             <p className="fav2"> Keine Favouriten ausgewählt</p>
//           )}
//         </div>
//         <div className="checklistsContainer">
//           {favArray.map((obj) => {
//             if (obj.isFavourite === true) {
//               return (
//                 <ChecklistContainer
//                   key={obj.id}
//                   dataUId={obj.id}
//                   isFav={obj.isFavourite}
//                   toggleFavorite={toggleFavorite}
//                   showSlMenu={showSlMenu}
//                   isTrue={showContext[obj.id]}
//                   handleDeleteChecklist={() =>
//                     handleDeleteChecklist(obj.title, obj.id)
//                   }
//                 ></ChecklistContainer>
//               );
//             }
//           })}
//         </div>
//       </div>
//       {dialog.isLoading && (
//         <Dialog
//           className="Dialog"
//           onDialog={(choose) => areUSureDelete(choose, dialog.checklistId)}
//           message={dialog.message}
//           checklistTitle={dialog.checklistTitle}
//           naviTrue={dialog.naviTrue}
//           naviFalse={dialog.naviFalse}
//           value="Löschen"
//           valueTwo="Abbrechen"
//         />
//       )}
//     </div>
//   );
// }

// export default Home;

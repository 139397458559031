import React from "react";
import { useLocation } from "react-router-dom";
import Checkboxes from "../../Components/Checkboxes/Checkboxes";
import getInputArray from "../../utils/checklistenLocalStorage";
import "../Checklist/Checklist.css";

function Checklist() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const checklistId = searchParams.get("checklistId");
  const data = getInputArray();
  const showChecklist = data?.find((checklist) => checklist.id === checklistId);
  return (
    <div className="Checklist">
      <div className="container">
        <Checkboxes checklistContent={showChecklist.id}></Checkboxes>
      </div>
    </div>
  );
}

export default Checklist;

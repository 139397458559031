import { useState } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import AddNewDailyChecklistPage from "../../Pages/DailyChecklist/AddNewDailyChecklistPage";
import "./NewChecklistPopup.css";

function NewChecklistPopup({ open }) {
  const [isPopupOpen, setIsPopupOpen] = useState(open);
  const [isNewChecklistPopupOpen, setIsNewChecklistPopupOpen] = useState(false);

  const closePopup = () => {
    setIsPopupOpen(false); // Nutze die setOpen-Funktion, um den Zustand in der Sidebar zu aktualisieren
  };

  const handleOpenAddDailyChecklist = () => {
    setIsNewChecklistPopupOpen(!isNewChecklistPopupOpen);
    setIsPopupOpen(false);
  };

  console.log("isNewChecklistPopupOpen", isNewChecklistPopupOpen);

  return (
    <div>
      {isNewChecklistPopupOpen && (
        <AddNewDailyChecklistPage
          open={isNewChecklistPopupOpen}
          pickedDay={new Date()}
        ></AddNewDailyChecklistPage>
      )}
      {isPopupOpen && (
        <div className="overlay">
          <div className="popup-container">
            <div className="close-popup">
              <FaTimes className="close-icon" onClick={closePopup} />
            </div>

            <h2>Neue Checkliste erstellen</h2>
            <div className="container-wrapper">
              <button
                className="checklist-container"
                onClick={handleOpenAddDailyChecklist}
              >
                <p className="title">Tagescheckliste</p>
                <FaPlus className="icon" />
              </button>
              <Link
                to="/newchecklist/step1"
                className="checklist-container"
                onClick={closePopup}
              >
                <p className="title">Allgemeine Checkliste</p>
                <FaPlus className="icon" />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewChecklistPopup;

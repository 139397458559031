import React, { useState } from "react";
import ChecklistContainer from "../../Components/ChecklistContainer/ChecklistContainer";
import Dialog from "../../Components/Dialog/Dialog";
import SortDropDown from "../../Components/SortDropDown/SortDropDown";
import getInputArray from "../../utils/checklistenLocalStorage";
import getDailyChecklistArray from "../../utils/dailyChecklistenLocalStorage";
import "../MyChecklists/MyChecklists.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddNewDailyChecklistPage from "../DailyChecklist/AddNewDailyChecklistPage";
import ShowDailyChecklistContainer from "../../Components/ChecklistContainer/ShowDailyChecklistContainer";

function MyChecklists() {
  const data = getInputArray();
  const [checklists, setChecklists] = useState(data);
  const [selectedItem, setSelectedItem] = useState([]);
  const [showContext, setShowContext] = useState({});
  let [checklistData, setChecklistData] = useState(getInputArray());
  let [checklistDailyData, setChecklistDailyData] = useState(
    getDailyChecklistArray()
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [dialog, setDialog] = useState({
    message: "",
    checklistTitle: "",
    isLoading: false,
    naviTrue: checklistData.length === 1 ? "/" : "/myChecklists",
    naviFalse: `/myChecklists`,
  });

  const item = ["Zuletzt hinzugefügt", "Favoriten", "Alphabetisch"];

  const toggleFavorite = (checklistId) => {
    const updatedChecklists = checklists.map((checklist) => {
      if (checklist.id === checklistId) {
        return { ...checklist, isFavourite: !checklist.isFavourite };
      }
      return checklist;
    });
    setChecklists(updatedChecklists);
    localStorage.setItem("InputArray", JSON.stringify(updatedChecklists));
  };

  //Wenn der Nutzer auf ein Item aus der Dropbox klickt, wird es hier dem setSelectedItem mitgegeben
  const onItemSelected = (item) => {
    setSelectedItem(item);
  };

  if (selectedItem === "Favoriten") {
    checklists.sort(done);
  } else if (selectedItem === "Alphabetisch") {
    checklists.sort(alphabetic);
  }

  function alphabetic(a, b) {
    if (a.title.toUpperCase() < b.title.toUpperCase()) {
      return -1;
    }
    if (a.title.toUpperCase() > b.title.toUpperCase()) {
      return 1;
    }
    return 0;
  }

  function done(a, b) {
    if (a.isFavourite > b.isFavourite) {
      return -1;
    }
    if (a.isFavourite < b.isFavourite) {
      return 1;
    }
    return 0;
  }

  const handleDialog = (
    message,
    isLoading,
    checklistTitle,
    navigaTrue,
    navigaFalse
  ) => {
    setDialog({
      message,
      isLoading,
      //Update
      checklistTitle,
      navigaTrue,
      navigaFalse,
    });
  };

  const handleDeleteChecklist = (checklistTitle, checklistId) => {
    setDialog({
      message: "Bist du dir sicher, dass du diese Checkliste löschen möchtest?",
      checklistTitle,
      checklistId,
      isLoading: true,
      naviTrue: checklistData.length === 1 ? "/" : "/myChecklists",
      naviFalse: `/myChecklists`,
    });
  };

  const showSlMenu = (dataUId) => {
    setShowContext((prevState) => ({
      ...prevState,
      [dataUId]: !prevState[dataUId],
    }));
  };

  const areUSureDelete = (choose, dataUId) => {
    if (choose) {
      const deleteChecklist = checklistData.filter(
        (checklist) => checklist.id !== dataUId
      );
      checklistData = deleteChecklist;
      localStorage.setItem("InputArray", JSON.stringify(deleteChecklist));
      setChecklistData([...checklistData]);
      handleDialog("", false);
    } else {
      handleDialog("", false);
    }
  };

  return (
    <>
      {isPopupOpen && (
        <AddNewDailyChecklistPage
          open={true}
          pickedDay={new Date()}
        ></AddNewDailyChecklistPage>
      )}
      <div className="MyChecklists">
        <div className="mainContainer">
          <h2 className="title">Alle Checklisten</h2>
          <h3>Tageschecklisten</h3>
          <div
            onClick={() => setIsPopupOpen(!isPopupOpen)}
            className="addNewDailyChecklist"
          >
            <FontAwesomeIcon icon={faPlus} /> Tagescheckliste hinzufügen
          </div>

          {checklistDailyData.length > 0 ? (
            <div className="dailyChecklistsContainer">
              {checklistDailyData.map((checklist) => {
                return (
                  checklist.date === new Date().toDateString() && (
                    <ShowDailyChecklistContainer
                      key={checklist.id}
                      dataUId={checklist.id}
                      isFav={checklist.isFavourite}
                      toggleFavorite={toggleFavorite}
                      showSlMenu={showSlMenu}
                      isTrue={showContext[checklist.id]}
                      handleDeleteChecklist={() =>
                        handleDeleteChecklist(checklist.title, checklist.id)
                      }
                    ></ShowDailyChecklistContainer>
                  )
                );
              })}
            </div>
          ) : (
            <p className="fav2">Du hast noch keine Checklisten erstellt</p>
          )}

          <h3>Allgemeine Checklisten</h3>
          <div className="dropdownContainer">
            <Link to="/newchecklist/step1">
              <FontAwesomeIcon icon={faPlus} /> Checkliste hinzufügen
            </Link>
            <SortDropDown
              sortItem={selectedItem}
              item={item}
              onItemSelected={onItemSelected}
            ></SortDropDown>
          </div>

          {checklists.length > 0 ? (
            <div className="dailyChecklistsContainer">
              {checklists.map((checklist) => {
                return (
                  <ChecklistContainer
                    key={checklist.id}
                    dataUId={checklist.id}
                    isFav={checklist.isFavourite}
                    toggleFavorite={toggleFavorite}
                    showSlMenu={showSlMenu}
                    isTrue={showContext[checklist.id]}
                    handleDeleteChecklist={() =>
                      handleDeleteChecklist(checklist.title, checklist.id)
                    }
                  ></ChecklistContainer>
                );
              })}
            </div>
          ) : (
            <p className="fav2">Du hast noch keine Checklisten erstellt</p>
          )}
        </div>

        {dialog.isLoading && (
          <Dialog
            className="Dialog"
            onDialog={(choose) => areUSureDelete(choose, dialog.checklistId)}
            message={dialog.message}
            checklistTitle={dialog.checklistTitle}
            naviTrue={dialog.naviTrue}
            naviFalse={dialog.naviFalse}
            value="Löschen"
            valueTwo="Abbrechen"
          />
        )}
      </div>
    </>
  );
}

export default MyChecklists;

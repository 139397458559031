import "./Footer.css";

function Footer() {
  return (
    <div className="FooterContainer">
      <div style={{ color: "var(--text-color)" }}>2023 © Binarystate GmbH</div>
    </div>
  );
}

export default Footer;

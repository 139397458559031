import React from "react";
import crown from "../../Images/icons8-krone-16.png";
import "./Progressbar.css";

const ProgressBar = ({ completed, label, desc, num, anzahlNum }) => {
  const containerStyles = {
    height: 13,
    width: "100%",
    backgroundColor: "var(--progress-color)",
    borderRadius: "7px",
    color: "var(--progressbar-description-color)",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: "rgb(188 107 46 / 80%)",
    borderRadius: "7px",
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  const progressLabel = `${num}/${anzahlNum}`;

  return (
    <div className="Progressbar">
      <div style={containerStyles} className="ProgressbarContainerStyle">
        {completed > 0 && (
          <div style={fillerStyles}>
            <span style={labelStyles} className="labelStyles"></span>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={crown}
              alt="BS Logo"
              className="small-logo"
              style={{ margin: "0 10px 0 0" }}
            />
            <p>Fortschritt</p>
          </div>
          <div>{progressLabel}</div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;

export const checkpointsDaylife = [
  {
    templateId: "daylifechecklist",
    templateTitle: "Arbeitsalltag Checkliste",
    checkpoints: [
      {
        checkpointsId: "daylife1",
        content: "Checklist-App lokal clonen",
      },
      {
        checkpointsId: "daylife2",
        content:
          "Neue Checkliste mit Checkpunkten aus der Vorlage Arbeitsalltag-Vorlage wählen",
      },
      {
        checkpointsId: "daylife3",
        content: "Eigenen Checkpunkt in die neue Checkliste hinzufügen",
      },

      {
        checkpointsId: "daylife4",
        content: "Teilnahme am NewsRoom",
      },
      {
        checkpointsId: "daylife5",
        content: "Teilnahme am Weekly",
      },
      {
        checkpointsId: "daylife6",
        content:
          "Status an was man heute gearbeitet hat im Synology Chat posten",
      },
      {
        checkpointsId: "daylife7",
        content:
          "Backup vom akutellen Stand des PC auf die Festplatte hochladen",
      },
      {
        checkpointsId: "daylife8",
        content: "Überprüfung und beantworten der E-Mails",
      },
      {
        checkpointsId: "daylife9",
        content: "Teilnahme am Daily",
      },
    ],
  },
];

import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const NextPageButton = (props) => {
  const { path, titleOn, titleBack, stylesprops, onSubmit } = props;

  return (
    <div className="nextPageBtn">
      <div className="link">
        <div className="btn">
          <NavLink className="on" to={path} onClick={onSubmit}>
            {titleOn}

            <FontAwesomeIcon
              icon={faArrowLeft}
              className="menu-icon"
              style={{
                transform: stylesprops,
              }}
            ></FontAwesomeIcon>
            {titleBack}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default NextPageButton;

/*<NavLink className="back" to={path} style={stylesprops}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="menu-icon"
              style={{ fontSize: "28px" }}
            ></FontAwesomeIcon>
          </NavLink>
          */

/*    style={{ fontSize: "28px", transform: "rotate(180deg)" } }*/

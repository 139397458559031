import React, { useState } from "react";
import { items } from "../../utils/templates.js";
import "./DropdownMenu.css";

function DropdownMenu({ onItemSelected }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (selectedItem) => {
    onItemSelected(selectedItem);
    toggleMenu();
  };

  return (
    <div className="dropdown">
      <select
        className="dropdown-select"
        onChange={(e) => handleItemClick(items[e.target.selectedIndex - 1])}
        defaultValue=""
      >
        <option value="" disabled hidden>
          Auswählen...
        </option>
        {items.map((item, index) => (
          <option key={item.id} value={index}>
            {item.map((template) => template.templateTitle)}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DropdownMenu;

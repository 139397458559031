import { useState } from "react";
import ProgressBar from "../Progressbar/Progressbar";
import getDailyChecklistArray from "../../utils/dailyChecklistenLocalStorage";
import "./DailyChecklistHomeContainer.css";
import { Link } from "react-router-dom";

function DailyChecklistHomeContainer({ date }) {
  console.log("date", date);

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  const getData = getDailyChecklistArray();

  const showDailyCheckpoints = getData.filter(
    (dailyChecklist) => dailyChecklist.date === date.toDateString()
  );

  const newID = showDailyCheckpoints.map((dailyChecklist) => dailyChecklist.id);

  const [showDailyCheckpointsisChecked, setShowDailyCheckpointsisChecked] =
    useState(
      showDailyCheckpoints.map((dailyChecklist) =>
        dailyChecklist.checkpoints.map((obj) => obj.isChecked)
      )
    );

  const [dataDailyChecklist, setDailyChecklist] = useState(
    getData.find((checklist) => checklist.id === newID[0])
  );
  const [isChecked, setIsChecked] = useState(
    dataDailyChecklist?.checkpoints.map((obj) => obj.isChecked)
  );
  const handleCheck = (id, parentId) => {
    const updatedData = getData.map((obj) => {
      if (obj.date === date.toDateString()) {
        const updatedCheckpoints = obj.checkpoints.map((checkID) => {
          if (checkID.checkpointsId === id) {
            if (!obj.recurring || isToday(obj.date)) {
              checkID.isChecked = !checkID.isChecked;

              if (checkID.subItem) {
                checkID.subItem.forEach((subItems) => {
                  subItems.isChecked = checkID.isChecked;
                });
              }
            }
          } else if (checkID.checkpointsId === parentId) {
            if (!obj.recurring || isToday(obj.date)) {
              checkID.isChecked = !checkID.isChecked;

              if (checkID.subItem) {
                checkID.subItem.forEach((subItems) => {
                  subItems.isChecked = false;
                });
              }
            }
          } else if (checkID.subItem) {
            const updatedSubItems = checkID.subItem.map((subItems) => {
              if (subItems.checkpointsId === id) {
                if (!obj.recurring || isToday(obj.date)) {
                  // Überprüfe, ob die Checkliste wiederkehrend ist und ob es der aktuelle Tag ist
                  subItems.isChecked = !subItems.isChecked;
                }
              }
              return subItems;
            });
            checkID.isChecked = updatedSubItems.every(
              (subItem) => subItem.isChecked
            );
            checkID.subItem = updatedSubItems;
          }
          return checkID;
        });
        return {
          ...obj,
          checkpoints: updatedCheckpoints,
        };
      }
      return obj;
    });

    console.log("updatedData", updatedData);

    const updatedChecklist = updatedData.find(
      (checklist) => checklist.id === newID[0]
    );

    localStorage.setItem("DailyChecklistArray", JSON.stringify(updatedData));
    setDailyChecklist(updatedChecklist);
    setShowDailyCheckpointsisChecked(
      updatedChecklist?.checkpoints.map((obj) => obj.isChecked) || []
    );
    setIsChecked(
      updatedChecklist?.checkpoints.map((obj) => obj.isChecked) || []
    );
  };

  let countIsChecked = 0;
  let countCheckpoints = 0;
  showDailyCheckpoints.map((checklist) => {
    countCheckpoints += checklist.checkpoints.length;
  });
  let numbinPercent = 0;
  showDailyCheckpoints.map((checklist) =>
    checklist.checkpoints.map(
      (checked) => checked.isChecked === true && countIsChecked++
    )
  );
  numbinPercent = (100 * countIsChecked) / countCheckpoints;

  return (
    <div className="DailyChecklistHomeContainer">
      <div className="scrollable-container">
        {console.log("showDailyCheckpoints", showDailyCheckpoints)}
        {showDailyCheckpoints.map((dailyChecklist) => {
          return dailyChecklist.checkpoints.map((dailyCheckpoints) => {
            return (
              <ul style={{ padding: "0" }} key={dailyCheckpoints.checkpointsId}>
                <li key={dailyCheckpoints.id} style={{ listStyle: "none" }}>
                  <div className="ListContainer"></div>
                  <div className="check-list-item">
                    <div className="left-section"></div>
                    <input
                      type="checkbox"
                      className="checkobox-square"
                      checked={dailyCheckpoints.isChecked}
                      onChange={() =>
                        handleCheck(
                          dailyCheckpoints.checkpointsId,
                          showDailyCheckpoints.id
                        )
                      }
                    />

                    <label
                      className="checkboxdefault"
                      htmlFor={`custom-checkbox-${dailyCheckpoints.checkpointsId}`}
                    >
                      {dailyCheckpoints.content}
                    </label>
                  </div>
                  <Link
                    to={`/dailychecklistpage?checklistId=${dailyChecklist.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <p className="dailyChecklistTitle">
                      {dailyChecklist.title}
                    </p>
                  </Link>
                  {console.log("dailyChecklist", dailyChecklist)}
                </li>
                <ul>
                  {dailyCheckpoints.subItem?.map((sub) => {
                    return (
                      <div className="SubListContainer" key={sub.id}>
                        <li style={{ listStyle: "none" }}>
                          <div className="ListContainer2"></div>
                          <div className="check-list-item">
                            <div className="sub-left-section ">
                              <input
                                type="checkbox"
                                className="checkobox-square"
                                checked={sub.isChecked}
                                onChange={() =>
                                  handleCheck(
                                    sub.checkpointsId,
                                    dailyCheckpoints.id
                                  )
                                }
                              />

                              <label
                                className="checkboxdefault"
                                htmlFor={`custom-checkbox-${sub.checkpointsId}`}
                              >
                                {sub.content}
                              </label>
                            </div>
                          </div>
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </ul>
            );
          });
        })}
      </div>
      <div className="progress">
        <ProgressBar
          completed={numbinPercent.toFixed(2)}
          num={countIsChecked}
          anzahlNum={countCheckpoints}
        ></ProgressBar>
      </div>
    </div>
  );
}

export default DailyChecklistHomeContainer;

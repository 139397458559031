import "./ChooseNewTemplate.css";
import React, { useState, useEffect } from "react";
import RadiobuttonCheckbox from "../../Components/RadiobuttonCheckbox/RadiobuttonCheckbox";
import DropdownMenu from "../../Components/DropdownMenu/DropdownMenu";
import NextPageButton from "../../Components/NextPageButton";
import getInputArray from "../../utils/checklistenLocalStorage";

function ChooseNewTemplate() {
  const [selectedItem, setSelectedItem] = useState([]);
  const [checkAll, setCheckAll] = useState();
  const [newArr, setNewArr] = useState([]);
  let idToEdit = localStorage.getItem("idToEdit");
  let data = getInputArray();

  useEffect(() => {
    setSelectedItem([]); // Zustand der ausgewählten Checkpunkte zurücksetzen, wenn die Komponente montiert wird
  }, []);

  const onItemSelected = (item) => {
    setSelectedItem(item);
  };

  const handleSaveCheckpoint = (el) => {
    setSelectedItem((prevSelectedItem) => {
      const updatedSelectedItem = prevSelectedItem.map((item) => {
        console.log("item.templateId", item.templateId);
        console.log("selectedItem[0]?.templateId", selectedItem[0]?.templateId);

        if (item.templateId === selectedItem[0]?.templateId) {
          return {
            ...item,
            checkpoints: item.checkpoints.map((checkpoint) => {
              if (checkpoint.checkpointsId === el.checkpointsId) {
                return {
                  ...checkpoint,
                  isChecked: el.isChecked,
                };
              }
              return checkpoint;
            }),
          };
        }
        return item;
      });

      const newSelectedCheckpoints = updatedSelectedItem
        .find((item) => item.templateId === selectedItem[0]?.templateId)
        ?.checkpoints.filter((checkpoint) => checkpoint.isChecked)
        .map((checkpoint) => checkpoint.content);

      setNewArr(newSelectedCheckpoints || []);
      console.log("updatedSelectedItem", updatedSelectedItem);

      return updatedSelectedItem;
    });
  };

  const handleCheckAll = (el) => {
    const _checkAll = !checkAll;
    setCheckAll(_checkAll);

    setSelectedItem((prevSelectedItem) => {
      const updatedSelectedItem = prevSelectedItem.map((item) => {
        if (item.templateId === selectedItem[0]?.templateId) {
          return {
            ...item,
            checkpoints: item.checkpoints.map((checkpoint) => ({
              ...checkpoint,
              isChecked: _checkAll,
            })),
          };
        }
        return item;
      });

      const newSelectedCheckpoints = updatedSelectedItem
        .find((item) => item.templateId === selectedItem[0]?.templateId)
        ?.checkpoints.filter((checkpoint) => checkpoint.isChecked)
        .map((checkpoint) => checkpoint.content);

      setNewArr(newSelectedCheckpoints || []);
      return updatedSelectedItem;
    });
  };

  const handleSave = () => {
    let checklistToEdit = data.find((checklist) => checklist.id === idToEdit);
    newArr.forEach((obj) => {
      const newItem = {
        checkpointsId: Math.floor(1000 + Math.random() * 9000),
        content: obj.toString(),
        isChecked: false,
      };
      checklistToEdit.checkpoints.push(newItem);
      localStorage.setItem("InputArray", JSON.stringify(data));
    });
  };

  const btn = {
    styles: {
      onbtn: {
        transform: "rotate(180deg)",
      },
    },
  };

  return (
    <div className="chooseNewTemplate">
      <div className="chooseNewTemplateLabel">
        <h1>
          Vorlage wählen <span style={{ fontSize: "25px" }}>(Optional)</span>
        </h1>
      </div>
      <div className="dropDownContainer">
        <DropdownMenu onItemSelected={onItemSelected}></DropdownMenu>
      </div>

      {selectedItem && selectedItem.length > 0 && (
        <div className="selectedItemContainer">
          <div className="titlecontainer">
            <div className="checklisttitle">
              <strong>{selectedItem[0]?.templateTitle} </strong>
            </div>
          </div>
          <div onClick={handleCheckAll} className="allSelect">
            <input
              type="checkbox"
              className="checkbox"
              name="allSelect"
              checked={checkAll}
            />
            <label>Alle auswählen</label>
          </div>

          <hr className="line"></hr>
          <div>
            {selectedItem[0].checkpoints.map((checkpoint, index) => (
              <RadiobuttonCheckbox
                key={`${checkpoint.checkpointsId}-${index}`} // Eindeutige Schlüsselprop
                content={checkpoint.content}
                checkpointsId={checkpoint.checkpointsId}
                name={`checkpoint${index}`}
                onChange={handleSaveCheckpoint}
                isChecked={checkpoint?.isChecked || false}
                value={checkpoint}
              ></RadiobuttonCheckbox>
            ))}
          </div>
        </div>
      )}
      <div className="buttonContainer">
        <NextPageButton
          titleBack={"Zurück"}
          path={"/newchecklist/step1"}
        ></NextPageButton>
        <NextPageButton
          titleOn={"Weiter"}
          stylesprops={btn.styles.onbtn.transform}
          path={"/newchecklist/step3"}
          onSubmit={handleSave}
        ></NextPageButton>
      </div>
    </div>
  );
}

export default ChooseNewTemplate;

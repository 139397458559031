import { SlIcon } from "@shoelace-style/shoelace/dist/react";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import "./CheckedCheckpoints.css";

function CheckedCheckpoints({
  checkpointsToEdit,
  setCheckpointsToEdit,
  selectedCheckpointId,
  onToggleSubItemInput,
  onAddSubItem,
  addItem,
  setAddItem,
  onDeleteCheckpoint,
  onDeleteSubCheckpoint,
}) {
  const [showInputField, setShowInputField] = useState(false);

  const handleToggleSubItemInput = (id) => {
    onToggleSubItemInput(id);
    setShowInputField(!showInputField);
  };

  const handleAddSubItem = (event, checkpointId) => {
    onAddSubItem(event, checkpointId);
  };

  const handleDeleteCheckpoint = (id) => {
    console.log("id", id);
    onDeleteCheckpoint(id);
  };

  const handleDeleteSubCheckpoint = (id) => {
    console.log("SubId", id);
    onDeleteSubCheckpoint(id);
  };

  return (
    <div className="CheckedCheckpoints">
      <div>
        <ul>
          {checkpointsToEdit.map((checkpoint) => (
            <div key={checkpoint.checkpointId}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  style={{
                    background: "none",
                    border: "none",
                    margin: "0",
                    padding: "0",
                  }}
                  onClick={() =>
                    handleDeleteCheckpoint(checkpoint.checkpointsId)
                  }
                >
                  <SlIcon
                    name="x"
                    style={{
                      minHeight: "25px",
                      minWidth: "25px",
                      color: "var(--primary-color)",
                      margin: "0px 0 0 -6px",
                    }}
                  ></SlIcon>
                </button>
                <li style={{ listStyle: "none" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {checkpoint.content}
                  </div>
                </li>
              </div>

              <button
                onClick={() =>
                  handleToggleSubItemInput(checkpoint.checkpointsId)
                }
                className="addSubCheckpointsBtn"
              >
                <SlIcon name="plus"></SlIcon>
                Füge Unterpunkte hinzu
              </button>
              {showInputField &&
                selectedCheckpointId === checkpoint.checkpointsId && (
                  <div className="subInputFeld">
                    <FaPlus style={{ width: "15px", height: "15px" }}></FaPlus>

                    <input
                      placeholder="Füge Unterpunkte hinzu"
                      className="subInput"
                      value={addItem}
                      onKeyDown={(e) =>
                        handleAddSubItem(e, checkpoint.checkpointsId)
                      }
                      onChange={(e) => setAddItem(e.target.value)}
                    />
                  </div>
                )}
              <div className="line"></div>

              {checkpoint.subItems && (
                <ul className="checkpointsUl">
                  <div className="checkpointsUlContainer">
                    {checkpoint.subItems.map((subItem) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <button
                          style={{ background: "none", border: "none" }}
                          onClick={() =>
                            handleDeleteSubCheckpoint(subItem.checkpointsId)
                          }
                        >
                          <SlIcon
                            name="x"
                            style={{
                              minWidth: "25px",
                              minHeight: "25px",
                              color: "var(--primary-color)",
                            }}
                          ></SlIcon>
                        </button>
                        <li
                          className="checkpointsLiContainer"
                          key={subItem.checkpointsId}
                        >
                          {subItem.content}
                        </li>
                      </div>
                    ))}
                  </div>
                </ul>
              )}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default CheckedCheckpoints;

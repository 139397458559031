import { SlIcon } from "@shoelace-style/shoelace/dist/react";
import React, { useState } from "react";
import { FaCheck, FaEdit, FaPlus, FaSort, FaTrash } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

import Dialog from "../../Components/Dialog/Dialog";
import getDailyChecklistArray from "../../utils/dailyChecklistenLocalStorage";

function DailyEditPage() {
  const [addItem, setAddItem] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [newCheckpoints, setNewCheckpoints] = useState([]);
  const [newSubCheckpoints, setNewSubCheckpoints] = useState([]);
  const [deletedCheckpoints, setDeletdCheckpoints] = useState([]);
  const [editSubCheckpoint, setEditSubCheckpoint] = useState([]);

  let [checklistData, setChecklistData] = useState(getDailyChecklistArray());
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search); //new URLSearchParams(location.search);
  const showTakenChecklistId = searchParams.get("showTakenChecklistId"); //searchParams.get("showTakenChecklistId");
  let [showTakenChecklist, setShowTakenChecklist] = useState([]);
  const [showAddSubItemId, setShowAddSubItemId] = useState(false);
  const [addSubItem, setAddSubItem] = useState("");

  showTakenChecklist = checklistData.find(
    (obj) => obj.id === showTakenChecklistId
  );
  const [dialog, setDialog] = useState({
    message: "",
    checklistTitle: "",
    isLoading: false,
    naviTrue: `/dailychecklistpage?checklistId=${showTakenChecklistId}`,
    naviFalse: `/dailyeditpage?showTakenChecklistId=${showTakenChecklistId}`,
  });

  const [saveDialog, setSaveDialog] = useState({
    message: "",
    checklistTitle: "",
    isLoading: false,
    naviTrue: `/dailychecklistpage?checklistId=${showTakenChecklistId}`,
    naviFalse: `/dailychecklistpage?checklistId=${showTakenChecklistId}`,
  });

  const [editCheckpoint, setEditCheckpoint] = useState({});

  const handleDialog = (
    message,
    isLoading,
    checklistTitle,
    navigaTrue,
    navigaFalse
  ) => {
    setDialog({
      message,
      isLoading,
      //Update
      checklistTitle,
      navigaTrue,
      navigaFalse,
    });
  };

  const handleTitleChange = (event) => {
    setEditTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setEditDescription(event.target.value);
  };
  const handleCheckpointChange = (checkpointId, value) => {
    setEditCheckpoint((prevState) => ({
      ...prevState,
      [checkpointId]: value.charAt(0).toUpperCase() + value.slice(1),
    }));
  };
  const handleSubCheckpointChange = (checkpointId, value) => {
    setEditSubCheckpoint((prevState) => ({
      ...prevState,
      [checkpointId]: value.charAt(0).toUpperCase() + value.slice(1),
    }));
  };

  //Speichert den Input vom User
  const handleAddItem = (event) => {
    if (addItem.length > 0 && event.code === "Enter") {
      const newCheckpoint = {
        checkpointsId: Math.floor(100 + Math.random() * 900),
        content: addItem.charAt(0).toUpperCase() + addItem.slice(1),
        isChecked: false,
      };

      setNewCheckpoints((prevCheckpoints) => [
        ...prevCheckpoints,
        newCheckpoint,
      ]);
      setAddItem("");
    }
  };
  //Delete checkpoint of checklist
  const handleDelete = (checkpointId) => {
    const deletedCheckpoint = showTakenChecklist.checkpoints.find(
      (checkpoint) => checkpoint.checkpointsId === checkpointId
    );

    if (deletedCheckpoint) {
      const updatedChecklistData = showTakenChecklist.checkpoints.filter(
        (checkpoint) => checkpoint.checkpointsId !== checkpointId
      );
      setDeletdCheckpoints([...deletedCheckpoints, deletedCheckpoint]);

      showTakenChecklist.checkpoints = updatedChecklistData;
      setChecklistData([...checklistData]);
    }
    const updatedNewCheckpoints = newCheckpoints.filter(
      (checkpoint) => checkpoint.checkpointsId !== checkpointId
    );
    setNewCheckpoints(updatedNewCheckpoints);
  };

  const handleDeleteSubItem = (checkpointId) => {
    const updatedChecklistData = { ...showTakenChecklist };

    updatedChecklistData.checkpoints.forEach((checkpoint) => {
      if (checkpoint.subItem) {
        const updatedSubItems = checkpoint.subItem.filter(
          (subCheckpoint) => subCheckpoint.checkpointsId !== checkpointId
        );
        checkpoint.subItem = updatedSubItems;
      }
    });
    console.log("updatedChecklistData", updatedChecklistData);

    showTakenChecklist.checkpoints = updatedChecklistData.checkpoints;
    //setNewCheckpoints(updatedChecklistData);
    setChecklistData([...checklistData]);
  };

  const applyForAllRepeats = (choose) => {
    if (choose) {
      const partialId = showTakenChecklistId.slice(
        0,
        showTakenChecklistId.length - 8
      );

      const updatedChecklistData = checklistData.map((checklist) => {
        if (checklist.id?.includes(partialId)) {
          const updatedCheckpoints = showTakenChecklist.checkpoints.map(
            (checkpoint) => {
              const updatedContent =
                editCheckpoint[checkpoint.checkpointsId] || checkpoint.content;
              const updatedSubItemsForCheckpoint = checkpoint.subItem?.map(
                (subItem) => {
                  const updatedContent =
                    editSubCheckpoint[subItem.checkpointsId] || subItem.content;
                  return {
                    ...subItem,
                    content:
                      updatedContent.charAt(0).toUpperCase() +
                      updatedContent.slice(1),
                  };
                }
              );

              return {
                ...checkpoint,
                content:
                  updatedContent.charAt(0).toUpperCase() +
                  updatedContent.slice(1),
                subItem: updatedSubItemsForCheckpoint,
              };
            }
          );

          return {
            ...checklist,
            title: editTitle.length > 0 ? editTitle : showTakenChecklist.title,
            description:
              editDescription.length > 0
                ? editDescription
                : showTakenChecklist.description,
            checkpoints: [...newCheckpoints, ...updatedCheckpoints],
          };
        } else {
          return checklist;
        }
      });

      {
        deletedCheckpoints.length > 0 &&
          updatedChecklistData.map((checklist) => {
            checklist.checkpoints.filter((checkpoint) => {
              deletedCheckpoints.forEach((item) => {
                checklist.checkpoints = checklist.checkpoints.filter(
                  (checkpoint) =>
                    checkpoint.checkpointsId !== item.checkpointsId
                );
              });
            });
          });
      }
      const updatedChecklistArray = [...updatedChecklistData].filter(
        (item) => item
      );
      setChecklistData(updatedChecklistArray);
      localStorage.setItem(
        "DailyChecklistArray",
        JSON.stringify(updatedChecklistArray)
      );
    } else {
      showTakenChecklist.title =
        editTitle.length > 0 ? editTitle : showTakenChecklist.title;
      showTakenChecklist.description =
        editDescription.length > 0
          ? editDescription
          : showTakenChecklist.description;

      const updatedCheckpoints = [
        ...newCheckpoints,
        ...showTakenChecklist.checkpoints.map((checkpoint) => {
          const updatedContent = editCheckpoint[checkpoint.checkpointsId]
            ? editCheckpoint[checkpoint.checkpointsId]
            : checkpoint.content;

          const updatedSubItems = checkpoint.subItem
            ? checkpoint.subItem.map((subItem) => {
                const updatedSubContent = editSubCheckpoint[
                  subItem.checkpointsId
                ]
                  ? editSubCheckpoint[subItem.checkpointsId]
                  : subItem.content;

                return {
                  ...subItem,
                  content:
                    updatedSubContent?.charAt(0).toUpperCase() +
                    updatedSubContent?.slice(1),
                };
              })
            : undefined;

          return {
            ...checkpoint,
            content:
              updatedContent?.charAt(0).toUpperCase() +
              updatedContent?.slice(1),
            subItem: updatedSubItems,
          };
        }),
      ];

      showTakenChecklist.checkpoints = updatedCheckpoints;

      const updatedChecklistData = checklistData.map((checklist) => {
        if (checklist.id === showTakenChecklistId) {
          return showTakenChecklist;
        } else {
          return checklist;
        }
      });

      setChecklistData(updatedChecklistData);
      localStorage.setItem(
        "DailyChecklistArray",
        JSON.stringify(updatedChecklistData)
      );
    }
  };

  //Save Edit Input
  const handleSaveEdit = () => {
    if (showTakenChecklist.repeatValue === "yes") {
      setSaveDialog({
        message:
          "Möchtest du die Änderungen auch für die Wiederholungen übernehmen?",
        checklistTitle: showTakenChecklist.title,
        isLoading: true,
        naviTrue: `/`,
        naviFalse: `/dailychecklistpage?checklistId=${showTakenChecklistId}`,
      });
    } else {
      const updateChecklistData = checklistData.map((checklist) =>
        checklist.id === showTakenChecklistId ? showTakenChecklist : checklist
      );
      editTitle.length > 0 && (showTakenChecklist.title = editTitle);
      editDescription.length > 0 &&
        (showTakenChecklist.description = editDescription);

      newCheckpoints.length > 0 &&
        showTakenChecklist.checkpoints.unshift(...newCheckpoints);

      editCheckpoint.length > 0 && setChecklistData([...checklistData]);
      setChecklistData(updateChecklistData);
      localStorage.setItem(
        "DailyChecklistArray",
        JSON.stringify(updateChecklistData)
      );
    }
  };

  //Deletes complete checklist
  const handleDeleteChecklist = () => {
    setDialog({
      message: "Bist du dir sicher, dass du diese Checkliste löschen möchtest?",
      checklistTitle: showTakenChecklist.title,
      isLoading: true,
      naviTrue: "/",
      naviFalse: `/dailyeditpage?showTakenChecklistId=${showTakenChecklistId}`,
    });
  };

  const handleStopChecklist = () => {
    setDialog({
      message:
        "Bist du dir sicher, dass sich diese Checkliste nicht mehr wiederholen soll?",
      checklistTitle: showTakenChecklist.title,
      isLoading: true,
      naviTrue: `/`,
      naviFalse: `/dailyeditpage?showTakenChecklistId=${showTakenChecklistId}`,
    });
  };

  const areUSureDelete = (choose) => {
    if (choose) {
      const deleteChecklist = checklistData.filter(
        (checklist) => checklist.id !== showTakenChecklistId
      );
      checklistData = deleteChecklist;
      localStorage.setItem(
        "DailyChecklistArray",
        JSON.stringify(deleteChecklist)
      );
      setChecklistData([...checklistData]);
    } else {
      handleDialog("", false);
    }
  };

  const areUSureStop = (choose) => {
    if (choose) {
      const filteredChecklists = checklistData.filter((checklist) =>
        checklist.id.includes("original")
      );

      const updatedChecklists = filteredChecklists.map((checklist) => ({
        ...checklist,
        repeatValue: "no",
        repeats: "never",
      }));

      checklistData = updatedChecklists;
      localStorage.setItem(
        "DailyChecklistArray",
        JSON.stringify(updatedChecklists)
      );
      setChecklistData([...checklistData]);
    } else {
      handleDialog("", false);
    }
  };

  const dragItem = useState([]);
  const dragOverItem = useState([]);

  const handleSort = () => {
    //dublicate items
    let _item = [...showTakenChecklist.checkpoints];

    //remove and save dragged Item content
    const [draggedItemContent] = _item.splice(dragItem.current, 1);

    //switch the position
    _item.splice(dragOverItem.current, 0, draggedItemContent);
    //reset the position
    dragItem.current = null;
    dragOverItem.current = null;

    //update the actual array
    showTakenChecklist.checkpoints = _item;
    setChecklistData([...checklistData]);
  };

  const handleShowAddSubItem = (id) => {
    setShowAddSubItemId(id);
  };

  const handleAddSubItem = (event, checkpointId) => {
    if (addSubItem.length > 0 && event.code === "Enter") {
      const newSubItem = {
        checkpointsId: Math.floor(100 + Math.random() * 900),
        content: addSubItem.charAt(0).toUpperCase() + addSubItem.slice(1),
        isChecked: false,
      };

      setNewSubCheckpoints((prevCheckpoints) => [
        ...prevCheckpoints,
        newSubItem,
      ]);
      // Füge das neue SubItem dem entsprechenden Checkpoint hinzu
      setNewCheckpoints((prevCheckpoints) => {
        console.log("prevCheckpoints", prevCheckpoints);
        return prevCheckpoints.map((checkpoint) => {
          if (checkpoint.checkpointsId === checkpointId) {
            return {
              ...checkpoint,
              subItem: [...(checkpoint.subItem || []), newSubItem],
            };
          }
          return checkpoint;
        });
      });

      console.log("newCheckpoints", newCheckpoints);
      const updatedCheckpointsItem = showTakenChecklist.checkpoints.map(
        (checkpoint) => {
          console.log("checkpoint", checkpoint);
          if (checkpoint.checkpointsId === checkpointId) {
            console.log("checkpointEINMALIG", checkpoint);

            const updatedSubItems = checkpoint.subItem
              ? [newSubItem, ...checkpoint.subItem]
              : [newSubItem];
            console.log("updatedSubItems", updatedSubItems);

            return {
              ...checkpoint,
              subItem: updatedSubItems,
            };
          }
          console.log("checkpoint", checkpoint);

          return checkpoint; // Return of the unchanged checkpoint
        }
      );
      showTakenChecklist.checkpoints = updatedCheckpointsItem;
      setChecklistData([...checklistData]);
      setAddSubItem("");
    }
  };
  console.log("newSubITem", newSubCheckpoints);

  return (
    <div className="EditPage">
      <div className="EditCheckboxes">
        <div key={showTakenChecklist.id}>
          <div className="headerContainer">
            <div className="save">
              {showTakenChecklist.repeatValue === "yes" ? (
                <button
                  style={{ background: "none", border: "none" }}
                  onClick={handleSaveEdit}
                >
                  <FaCheck
                    style={{
                      minHeight: "20px",
                      minWidth: "20px",
                      color: "rgb(188, 107, 46) ",
                    }}
                  ></FaCheck>
                </button>
              ) : (
                <Link
                  to={`/dailychecklistpage?checklistId=${showTakenChecklistId}`}
                  style={{ color: "#BC6B2E" }}
                >
                  <FaCheck
                    onClick={handleSaveEdit}
                    style={{
                      minHeight: "20px",
                      minWidth: "20px",
                      color: "rgb(188, 107, 46) ",
                    }}
                  ></FaCheck>
                </Link>
              )}
              {saveDialog.isLoading && (
                <Dialog
                  onDialog={applyForAllRepeats}
                  message={saveDialog.message}
                  checklistTitle={saveDialog.checklistTitle}
                  naviTrue={saveDialog.naviTrue}
                  naviFalse={saveDialog.naviFalse}
                  value="Ja"
                  valueTwo="Nein"
                />
              )}
            </div>
            <Link
              to={`/dailychecklistpage?checklistId=${showTakenChecklistId}`}
              style={{ width: "30px", height: "30px", color: "black" }}
            >
              <div className="notSave">
                <FaPlus
                  style={{
                    rotate: "45deg",
                    minHeight: "20px",
                    minWidth: "20px",
                  }}
                ></FaPlus>
              </div>
            </Link>
          </div>
          <div className="container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginRight: "10px",
              }}
            >
              <strong>Titel</strong>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaEdit
                style={{
                  minHeight: "20px",
                  minWidth: "20px",
                }}
              ></FaEdit>
              <input
                className="headerInput"
                id="title"
                key="title"
                type="text"
                value={editTitle || showTakenChecklist.title}
                onChange={handleTitleChange}
              ></input>
            </div>
            <br />
            <strong>Beschreibung</strong>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaEdit
                style={{
                  minHeight: "20px",
                  minWidth: "20px",
                }}
              ></FaEdit>
              <textarea
                id="description"
                key="description"
                className="headerInput"
                type="text"
                placeholder="Füge eine Beschreibung hinzu"
                defaultValue={showTakenChecklist.description}
                value={editDescription || showTakenChecklist.description}
                onChange={handleDescriptionChange}
              ></textarea>
            </div>
          </div>

          <div
            className="innerContainer"
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaPlus style={{ minHeight: "15px", minWidth: "15px" }}></FaPlus>
            <input
              className="input"
              placeholder="Füge eigene Checkpunkte hinzu und drück Enter um die Änderung zu übernehmen"
              value={addItem}
              onKeyDown={handleAddItem}
              onChange={(e) => setAddItem(e.target.value)}
            ></input>
          </div>
          <div className="innerContainer">
            <ul className="checkbox-list">
              {newCheckpoints.map((obj, index) => {
                return (
                  <li
                    className="li-item"
                    key={obj.id}
                    draggable
                    onDragStart={() => (dragItem.current = index)}
                    onDragEnter={() => (dragOverItem.current = index)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <div className="ListContainer"></div>
                    <div className="line"></div>
                    <div className="check-list-item">
                      <div className="left-section">
                        <FaTrash
                          onClick={() => handleDelete(obj.checkpointsId)}
                          style={{
                            minHeight: "20px",
                            minWidth: "20px",
                            color: "#BC6B2E",
                            cursor: "pointer",
                          }}
                        ></FaTrash>
                        <input
                          className="headerInput"
                          id="checkpoint"
                          key="checkpoint"
                          type="text"
                          value={
                            editCheckpoint[obj.checkpointsId]
                              ? editCheckpoint[obj.checkpointsId]
                              : obj.content
                          }
                          onChange={(e) =>
                            handleCheckpointChange(
                              obj.checkpointsId,
                              e.target.value
                            )
                          }
                        ></input>
                        <FaSort
                          style={{
                            minHeight: "20px",
                            minWidth: "20px",
                            color: "#BC6B2E",
                            margin: "0 10px",
                            cursor: "move",
                          }}
                        ></FaSort>
                        <button
                          style={{ background: "none", border: "none" }}
                          onClick={() =>
                            handleShowAddSubItem(obj.checkpointsId)
                          }
                        >
                          <FaPlus
                            style={{ minHeight: "15px", minWidth: "15px" }}
                          ></FaPlus>
                        </button>
                      </div>
                      {showAddSubItemId === obj.checkpointsId && (
                        <div className="subItemInput">
                          <FaPlus
                            style={{ height: "15px", width: "15px" }}
                          ></FaPlus>
                          <input
                            placeholder="Füge Unterpunkte hinzu"
                            className="subInput"
                            value={addSubItem}
                            onKeyDown={(e) =>
                              handleAddSubItem(e, obj.checkpointsId)
                            }
                            onChange={(e) => setAddSubItem(e.target.value)}
                          />
                        </div>
                      )}
                    </div>

                    <ul className="checkbox-list">
                      {obj.subItem?.map((obj2) => {
                        return (
                          <li className="li-item" key={obj2.id}>
                            <div className="ListContainer"></div>
                            <div className="sub-line"></div>
                            <div className="check-list-sub-item">
                              <div className="left-section">
                                <FaTrash
                                  onClick={() =>
                                    handleDeleteSubItem(obj2.checkpointsId)
                                  }
                                  style={{
                                    minHeight: "20px",
                                    minWidth: "20px",
                                    color: "#BC6B2E",
                                    cursor: "pointer",
                                  }}
                                ></FaTrash>
                                <input
                                  className="subItemCheckpoint"
                                  id="checkpoint"
                                  key="checkpoint"
                                  type="text"
                                  value={
                                    editSubCheckpoint[obj2.checkpointsId]
                                      ? editSubCheckpoint[obj2.checkpointsId]
                                      : obj2.content
                                  }
                                  onChange={(e) =>
                                    handleSubCheckpointChange(
                                      obj2.checkpointsId,
                                      e.target.value
                                    )
                                  }
                                ></input>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
            <ul className="checkbox-list">
              {showTakenChecklist.checkpoints?.map((obj, index) => {
                return (
                  <li
                    className="li-item"
                    key={obj.id}
                    draggable
                    onDragStart={() => (dragItem.current = index)}
                    onDragEnter={() => (dragOverItem.current = index)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <div className="ListContainer"></div>
                    <div className="line"></div>
                    <div className="check-list-item">
                      <div className="left-section">
                        <FaTrash
                          onClick={() => handleDelete(obj.checkpointsId)}
                          style={{
                            minHeight: "20px",
                            minWidth: "20px",
                            color: "#BC6B2E",
                            cursor: "pointer",
                          }}
                        ></FaTrash>
                        <input
                          className="headerInput"
                          id="checkpoint"
                          key="checkpoint"
                          type="text"
                          value={
                            editCheckpoint[obj.checkpointsId]
                              ? editCheckpoint[obj.checkpointsId]
                              : obj.content
                          }
                          onChange={(e) =>
                            handleCheckpointChange(
                              obj.checkpointsId,
                              e.target.value
                            )
                          }
                        ></input>
                        <FaSort
                          style={{
                            minHeight: "20px",
                            minWidth: "20px",
                            color: "#BC6B2E",
                            margin: "0 10px",
                            cursor: "move",
                          }}
                        ></FaSort>
                        <button
                          style={{ background: "none", border: "none" }}
                          onClick={() =>
                            handleShowAddSubItem(obj.checkpointsId)
                          }
                        >
                          <FaPlus
                            style={{ minHeight: "15px", minWidth: "15px" }}
                          ></FaPlus>
                        </button>
                      </div>
                      {showAddSubItemId === obj.checkpointsId && (
                        <div className="subItemInput">
                          <FaPlus
                            style={{ height: "15px", width: "15px" }}
                          ></FaPlus>
                          <input
                            placeholder="Füge Unterpunkte hinzu"
                            className="subInput"
                            value={addSubItem}
                            onKeyDown={(e) =>
                              handleAddSubItem(e, obj.checkpointsId)
                            }
                            onChange={(e) => setAddSubItem(e.target.value)}
                          />
                        </div>
                      )}
                    </div>
                    <ul className="checkbox-list">
                      {obj.subItem?.map((obj2) => {
                        return (
                          <li className="li-item" key={obj2.id}>
                            <div className="ListContainer"></div>
                            <div className="sub-line"></div>
                            <div className="check-list-sub-item">
                              <div className="left-section">
                                <FaTrash
                                  onClick={() =>
                                    handleDeleteSubItem(obj2.checkpointsId)
                                  }
                                  style={{
                                    minHeight: "20px",
                                    minWidth: "20px",
                                    color: "#BC6B2E",
                                    cursor: "pointer",
                                  }}
                                ></FaTrash>
                                <input
                                  className="subItemCheckpoint"
                                  id="checkpoint"
                                  key="checkpoint"
                                  type="text"
                                  value={
                                    editSubCheckpoint[obj2.checkpointsId]
                                      ? editSubCheckpoint[obj2.checkpointsId]
                                      : obj2.content
                                  }
                                  onChange={(e) =>
                                    handleSubCheckpointChange(
                                      obj2.checkpointsId,
                                      e.target.value
                                    )
                                  }
                                ></input>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>

          {showTakenChecklist.repeatValue === "no" ? (
            <div className="deleteBtnContainer">
              <button className="deleteButton" onClick={handleDeleteChecklist}>
                Checkliste löschen
              </button>
              {dialog.isLoading && (
                <Dialog
                  onDialog={areUSureDelete}
                  message={dialog.message}
                  checklistTitle={dialog.checklistTitle}
                  naviTrue={dialog.naviTrue}
                  naviFalse={dialog.naviFalse}
                  value="Löschen"
                  valueTwo="Abbrechen"
                />
              )}
            </div>
          ) : (
            <div className="deleteBtnContainer">
              <div className="deleteBtnContainer">
                <button className="deleteButton" onClick={handleStopChecklist}>
                  Wiederholen beenden
                </button>
                {dialog.isLoading && (
                  <Dialog
                    onDialog={areUSureStop}
                    message={dialog.message}
                    checklistTitle={dialog.checklistTitle}
                    naviTrue={dialog.naviTrue}
                    naviFalse={dialog.naviFalse}
                    value="Beenden"
                    valueTwo="Abbrechen"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DailyEditPage;

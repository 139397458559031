export const checkpointsOnboarding = [
  {
    templateId: "onboarding",
    templateTitle: "Onboarding Checkliste",
    description:
      "Hier ist deine Onboarding-Checkliste, auf der du alle wichtigen Punkte für deinen Arbeitsbeginn abhakst. Es soll dir dabei helfen den Überblick zu bewahren, wenn alles Neue gerade auf dich zukommt.",
    checkpoints: [
      {
        checkpointsId: "onb1",
        content:
          "Begrüßung und Vorstellung des Teams und der Unternehmenskultur",
      },
      {
        checkpointsId: "onb2",
        content:
          "Bereitstellung von Unternehmensinformationen wie Mission, Vison und Werte",
      },
      {
        checkpointsId: "onb3",
        content:
          "Vorstellung des Organigramms und der verschienen Abteilungen im Unternehmen",
      },

      {
        checkpointsId: "onb4",
        content:
          "Bereitstellung von Zugangsdaten und Einweisung in interne Systeme und Tools",
      },
      {
        checkpointsId: "onb5",
        content: "Einrichtung von Arbeitsplatz und technischer Ausstattung",
      },
      {
        checkpointsId: "onb6",
        content:
          "Einführung in die Unternehmensrichtlinien, Arbeitszeiten und Urlaubsregelungen",
      },
      {
        checkpointsId: "onb7",
        content: "Informationen zu den Teammeetings und Kommunikationskanälen",
      },
      {
        checkpointsId: "onb8",
        content:
          "Informationen zur Arbeitsplatzsicherheit und Notfallverfahren",
      },
      {
        checkpointsId: "onb9",
        content:
          "Einweisung in die Personalabteilung und administrative Prozesse (z.B. Zeiterfassung)",
      },
      {
        checkpointsId: "onb10",
        content:
          "Klären von Fragen zum Arbeitsvertrag und den Beschäftigungsbedingungen",
      },
    ],
  },
];

import { SlIcon } from "@shoelace-style/shoelace/dist/react";
import { FaCube } from "react-icons/fa";
import "./MyAccount.css";

function MyAccount() {
  return (
    <div className="MyAccount">
      <div className="mainContainer">
        <div className="titleContainer">
          <h2>Mein Konto</h2>
        </div>
        <div className="iconContainer">
          <SlIcon
            name="cup-hot"
            style={{ color: "#86888E", width: "100px", height: "100px" }}
          ></SlIcon>
          <div style={{ width: "330px" }}>
            <p className="textContainer">Hier entsteht in Kürze etwas.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyAccount;

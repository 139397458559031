import React, { useEffect, useState } from "react";
import "./CreateChecklist.css";
import InputArray from "../../utils/InputArray";
import { Link, useParams } from "react-router-dom";
import NextPageButton from "../../Components/NextPageButton";
import { v4 } from "uuid";
import getInputArray from "../../utils/checklistenLocalStorage";

const CreateChecklist = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  let checklists = getInputArray();

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  //Speichert den Input vom User
  const handleSaveClick = () => {
    if (title.length > 0) {
      const newItem = {
        id: v4(),
        title: title.charAt(0).toUpperCase() + title.slice(1),
        description: description,
        isFavourite: false,
        checkpoints: [],
      };
      checklists.unshift(newItem);
      let idToEdit = newItem.id;
      localStorage.setItem("idToEdit", idToEdit);
      localStorage.setItem("InputArray", JSON.stringify(checklists));
    } else {
      alert("Give your Checklist a Title");
    }
  };

  const btn = {
    styles: {
      onbtn: {
        transform: "rotate(180deg)",
      },
    },
    position: {
      right: "80px",
      width: "5%",
    },
  };
  return (
    <div className="createchecklist">
      <div className="newChecklistLabel">
        <h1>Checkliste erstellen</h1>
        <p>
          Gebe deiner Checkliste einen Namen und eine Beschreibung, damit du
          genau wissen, wofür du sie verwendest und erstelle deine eigene
          individuelle Checkliste, indem du Elemente aus den Vorlagen und/oder
          deine eigenen Elemente hinzufügst.
        </p>
      </div>

      <div className="titlecontainer">
        <div className="checklistTitle">
          <strong>Titel</strong>
          <label htmlFor="title">
            <br />

            <input
              id="title"
              type="text"
              key="title"
              placeholder="Titel"
              value={title}
              onChange={handleTitleChange}
            />
          </label>
        </div>
        <div className="checklistDescription">
          <label htmlFor="description">
            <strong>
              Beschreibung <span style={{ fontSize: "25px" }}>(Optional)</span>
            </strong>
            <br />
            <textarea
              id="description"
              key="description"
              placeholder="Beschreibe deine Checkliste in kurzen Worten"
              rows="4"
              value={description}
              onChange={handleDescriptionChange}
            />
          </label>
          <div className="buttonContainer">
            <NextPageButton
              titleOn={"Weiter"}
              stylesprops={btn.styles.onbtn.transform}
              path={title.length > 0 && "/newchecklist/step2"}
              position={btn.position.position}
              right={btn.position.right}
              width={btn.position.width}
              onSubmit={handleSaveClick}
            ></NextPageButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateChecklist;

import { useEffect, useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";

import "./DarkModeToggle.css";

const DarkModeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState();
  console.log("isDarkMode", isDarkMode);

  useEffect(() => {
    // Überprüfen, ob der Theme-Modus im localStorage gespeichert ist
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setIsDarkMode(storedTheme === "dark");
      applyTheme(storedTheme);
    } else {
      const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
      setIsDarkMode(darkThemeMq.matches);
      applyTheme(darkThemeMq.matches ? "dark" : "light");
    }

    const updateTheme = (e) => {
      setIsDarkMode(e.matches);
      applyTheme(e.matches ? "dark" : "light");
    };

    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    darkThemeMq.addEventListener("change", updateTheme);

    return () => {
      darkThemeMq.removeEventListener("change", updateTheme);
    };
  }, []);

  const applyTheme = (theme) => {
    const root = document.documentElement;
    if (theme === "dark") {
      root.classList.add("theme-dark");
      root.classList.remove("theme-light");
    } else {
      root.classList.add("theme-light");
      root.classList.remove("theme-dark");
    }
  };

  const toggleDarkMode = (checked) => {
    const theme = checked ? "dark" : "light";
    applyTheme(theme);
    localStorage.setItem("theme", theme);
    setIsDarkMode(checked);
  };

  return (
    <Toggle
      checked={isDarkMode}
      onChange={({ target }) => toggleDarkMode(target.checked)}
      icons={{ checked: "🌙", unchecked: "🔆" }}
      aria-label="Dark mode toggle"
    />
  );
};

export default DarkModeToggle;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../RadiobuttonCheckbox/RadiobuttonCheckbox.css";

function RadiobuttonCheckbox({
  checkpointsId,
  content,
  name,
  onClick,
  onChange,
  isChecked,
}) {
  const [checked, setChecked] = useState(false);

  // Call function, when isChecked prop change
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  // Handle click the checkbox to set checked state
  const handleClick = () => {
    const _checked = !checked;

    setChecked(_checked);

    onChange({
      checkpointsId: checkpointsId,
      content: content,
      isChecked: _checked,
    });
  };

  return (
    <div className="radiobuttonCheckbox">
      <label>
        <div className="RadiobtnContainer" onClick={handleClick}>
          <input
            type="checkbox"
            name={name}
            checked={checked}
            className="radiobutton-checkbox"
          ></input>
          <label>{content}</label>
        </div>
      </label>
    </div>
  );
}

RadiobuttonCheckbox.propTypes = {
  isChecked: PropTypes.bool,
};

RadiobuttonCheckbox.defaultProps = {
  isChecked: false,
};

export default RadiobuttonCheckbox;

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./SortDropDown.css";
import { SlIcon } from "@shoelace-style/shoelace/dist/react";

function SortDropDown({ sortItem, item, onItemSelected, defaultValue, value }) {
  const [isOpen, setIsOpen] = useState(false);
  const smallDropDown = () => window.innerWidth > 533;
  const [showSmallDropDown, setShowSmallDropDown] = useState(smallDropDown);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    onItemSelected(item);
    toggleMenu();
  };

  if (sortItem.length === 0) {
    sortItem = item[0];
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      setShowSmallDropDown(smallDropDown());
    });
  }, []);

  return (
    <>
      {" "}
      <div className="SortDropDown">
        <button onClick={toggleMenu} className="sort-dropdown-toggle">
          <div style={{ display: "flex" }}>
            <div className="sortByItem">{sortItem}</div>

            <div className="sort-dropdown-toggle-icon">
              <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            </div>
          </div>

          {isOpen && (
            <ul className="sort-dropdown-menu">
              {item.map((obj) => {
                return (
                  <li key={item.id} onClick={() => handleItemClick(obj)}>
                    <div>{obj}</div>
                  </li>
                );
              })}
            </ul>
          )}
        </button>
      </div>
    </>
  );
}

export default SortDropDown;

// {" "}
//       {showSmallDropDown ? (
//         <div className="SortDropDown">
//           <button onClick={toggleMenu} className="sort-dropdown-toggle">
//             <span id="fadeshow1">Sortieren nach:</span>

//             <div className="sortByItem">{sortItem}</div>

//             <div className="sort-dropdown-toggle-icon">
//               <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
//             </div>
//             {isOpen && (
//               <ul className="sort-dropdown-menu">
//                 {item.map((obj) => {
//                   return (
//                     <li key={item.id} onClick={() => handleItemClick(obj)}>
//                       <div>{obj}</div>
//                     </li>
//                   );
//                 })}
//               </ul>
//             )}
//           </button>
//         </div>
//       ) : (
//         <div>
//           <button onClick={toggleMenu} className="sort-dropdown-toggle">
//             <SlIcon
//               name="filter-left"
//               style={{ minWidth: "20px", minHeight: "20px" }}
//             ></SlIcon>
//             <div className="sort-dropdown-toggle-icon">
//               <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
//             </div>
//             {isOpen && (
//               <ul className="sort-dropdown-menu">
//                 {item.map((obj) => {
//                   return (
//                     <li key={item.id} onClick={() => handleItemClick(obj)}>
//                       <div>{obj}</div>
//                     </li>
//                   );
//                 })}
//               </ul>
//             )}
//           </button>
//         </div>
//       )}
//     </>
//   );
// }

import { CheckedCheckpoints, NextPageButton } from "../../Components";
import { useState } from "react";
import getInputArray from "../../utils/checklistenLocalStorage";
import "./AddNewCheckpoints.css";
import { SlIcon } from "@shoelace-style/shoelace/dist/react";
import { FaPlus } from "react-icons/fa";

function AddNewCheckpoints() {
  const [checklistData, setChecklistData] = useState(getInputArray());
  const idToEdit = localStorage.getItem("idToEdit");
  const [addItem, setAddItem] = useState("");
  const [addSubItem, setAddSubItem] = useState("");
  const [addHyperItem, setAddHyperItem] = useState("");
  const [selectedCheckpointId, setSelectedCheckpointId] = useState(null);

  let checklistToEdit;
  if (Array.isArray(checklistData)) {
    checklistToEdit = checklistData.find(
      (checklist) => checklist.id === idToEdit
    );
  } else {
    checklistToEdit = checklistData;
  }

  const handleSaveClick = (event) => {
    if (addHyperItem.length === 0) {
      if (addItem.length > 0 && event.code === "Enter") {
        const newItem = {
          checkpointsId: Math.floor(1000 + Math.random() * 9000),
          content: addItem.charAt(0).toUpperCase() + addItem.slice(1),
          isChecked: false,
        };

        checklistToEdit.checkpoints.unshift(newItem);
        setChecklistData([...checklistData]);
        localStorage.setItem("InputArray", JSON.stringify(checklistData));
        setAddItem("");
      }
    }
  };

  const handleToggleSubItemInput = (id) => {
    setSelectedCheckpointId(id);
  };

  const handleAddSubItem = (event, checkpointId) => {
    if (addSubItem.length > 0 && event.code === "Enter") {
      const newSubItem = {
        checkpointsId: Math.floor(100 + Math.random() * 900),
        content: addSubItem.charAt(0).toUpperCase() + addSubItem.slice(1),
        isChecked: false,
      };

      const updatedCheckpoints = checklistToEdit.checkpoints.map(
        (checkpoint) => {
          if (checkpoint.checkpointsId === checkpointId) {
            // Create a new subItems array by adding the new subItem
            const updatedSubItems = checkpoint.subItems
              ? [...checkpoint.subItems, newSubItem]
              : [newSubItem];

            return {
              ...checkpoint,
              subItems: updatedSubItems,
            };
          }
          return checkpoint;
        }
      );

      const updatedChecklist = {
        ...checklistToEdit,
        checkpoints: updatedCheckpoints,
      };

      if (Array.isArray(checklistData)) {
        const updatedChecklistData = checklistData.map((checklist) =>
          checklist.id === idToEdit ? updatedChecklist : checklist
        );
        setChecklistData(updatedChecklistData);
        localStorage.setItem(
          "InputArray",
          JSON.stringify(updatedChecklistData)
        );
      } else {
        setChecklistData(updatedChecklist);
        localStorage.setItem("InputArray", JSON.stringify(updatedChecklist));
      }

      setAddSubItem("");
    }
  };

  const handleDeleteCheckpoint = (id) => {
    const updateCheckpoints = checklistToEdit.checkpoints.filter(
      (checkpoint) => checkpoint.checkpointsId !== id
    );
    checklistToEdit.checkpoints = updateCheckpoints;
    setChecklistData([...checklistData]);
    localStorage.setItem("InputArray", JSON.stringify(checklistData));
  };

  const handleDeleteSubCheckpoint = (id) => {
    const updatedCheckpoints = checklistToEdit.checkpoints.map((checkpoint) => {
      const updatedSubItems = checkpoint.subItems?.filter(
        (sub) => sub.checkpointsId !== id
      );
      return {
        ...checkpoint,
        subItems: updatedSubItems,
      };
    });

    const updatedChecklist = {
      ...checklistToEdit,
      checkpoints: updatedCheckpoints,
    };

    if (Array.isArray(checklistData)) {
      const updatedChecklistData = checklistData.map((checklist) =>
        checklist.id === idToEdit ? updatedChecklist : checklist
      );
      setChecklistData(updatedChecklistData);
      localStorage.setItem("InputArray", JSON.stringify(updatedChecklistData));
    } else {
      setChecklistData(updatedChecklist);
      localStorage.setItem("InputArray", JSON.stringify(updatedChecklist));
    }
  };

  const btn = {
    styles: {
      onbtn: {
        transform: "rotate(180deg)",
      },
    },
  };

  return (
    <div className="AddNewCheckpoints">
      <div>
        <div className="title">{checklistToEdit.title}</div>
        <div className="AddNewCheckpointsContainer">
          <FaPlus style={{ minHeight: "15px", minWidth: "15px" }}></FaPlus>
          <input
            className="input"
            placeholder="Füge einen Checkpoint hinzu und drücke Enter, um ihn zu übernehmen"
            value={addItem}
            onKeyDown={handleSaveClick}
            onChange={(e) => setAddItem(e.target.value)}
          ></input>
        </div>
      </div>
      <div>
        <CheckedCheckpoints
          checkpointsToEdit={checklistToEdit.checkpoints}
          selectedCheckpointId={selectedCheckpointId}
          onToggleSubItemInput={handleToggleSubItemInput}
          onAddSubItem={handleAddSubItem}
          addItem={addSubItem}
          setAddItem={setAddSubItem}
          onDeleteCheckpoint={handleDeleteCheckpoint}
          onDeleteSubCheckpoint={handleDeleteSubCheckpoint}
        ></CheckedCheckpoints>
      </div>
      <div className="buttonContainer">
        <NextPageButton
          titleBack={"Zurück"}
          path={"/newchecklist/step2"}
        ></NextPageButton>
        <NextPageButton
          titleOn={"Weiter"}
          stylesprops={btn.styles.onbtn.transform}
          path={"/newchecklist/step4"}
          onSubmit={handleSaveClick}
        ></NextPageButton>
      </div>
    </div>
  );
}

export default AddNewCheckpoints;

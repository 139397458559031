import { SlIcon } from "@shoelace-style/shoelace/dist/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { NextPageButton } from "../../Components";
import getInputArray from "../../utils/checklistenLocalStorage";
import "./Overview.css";

function Overview() {
  let [checklistData, setChecklistData] = useState(getInputArray());

  const idToEdit = localStorage.getItem("idToEdit");
  let [checklistToEdit, setChecklistToEdit] = useState([]);
  checklistToEdit = checklistData.find(
    (checklist) => checklist.id === idToEdit
  );

  const title = checklistToEdit.title;
  const description = checklistToEdit.description;
  const checklistId = checklistToEdit.id;

  const handleDeleteCheckpoint = (id) => {
    const updateCheckpoints = checklistToEdit.checkpoints.filter(
      (checkpoint) => checkpoint.checkpointsId !== id
    );
    checklistToEdit.checkpoints = updateCheckpoints;
    setChecklistData([...checklistData]);
    localStorage.setItem("InputArray", JSON.stringify(checklistData));
  };

  const handleDeleteSubCheckpoint = (id) => {
    const updatedCheckpoints = checklistToEdit.checkpoints.map((checkpoint) => {
      const updatedSubItems = checkpoint.subItems?.filter(
        (sub) => sub.checkpointsId !== id
      );
      return {
        ...checkpoint,
        subItems: updatedSubItems,
      };
    });

    const updatedChecklist = {
      ...checklistToEdit,
      checkpoints: updatedCheckpoints,
    };

    if (Array.isArray(checklistData)) {
      const updatedChecklistData = checklistData.map((checklist) =>
        checklist.id === idToEdit ? updatedChecklist : checklist
      );
      setChecklistData(updatedChecklistData);
      localStorage.setItem("InputArray", JSON.stringify(updatedChecklistData));
    } else {
      setChecklistData(updatedChecklist);
      localStorage.setItem("InputArray", JSON.stringify(updatedChecklist));
    }
  };

  return (
    <div className="Overview">
      <div className="OverviewHeaderContainer">
        <div>
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
      </div>
      <ul>
        {checklistToEdit.checkpoints?.map((obj) => {
          return (
            <div>
              <div className="line"></div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  style={{
                    background: "none",
                    border: "none",
                    color: "var(--text-color)",
                  }}
                  onClick={() => handleDeleteCheckpoint(obj.checkpointsId)}
                >
                  <SlIcon
                    name="x"
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "var(--primary-color)",
                    }}
                  ></SlIcon>
                </button>
                <li>{obj?.content}</li>
              </div>
              <div className="subItemList">
                {obj?.subItems?.map((sub) => {
                  return (
                    <ul className="subItemUl">
                      <div className="line"></div>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                          style={{
                            background: "none",
                            border: "none",
                            color: "var(--text-color)",
                          }}
                          onClick={() =>
                            handleDeleteSubCheckpoint(sub.checkpointsId)
                          }
                        >
                          <SlIcon
                            name="x"
                            style={{
                              width: "30px",
                              height: "30px",
                              color: "var(--primary-color)",
                            }}
                          ></SlIcon>
                        </button>
                        <li>{sub.content}</li>
                      </div>
                    </ul>
                  );
                })}
              </div>
            </div>
          );
        })}
      </ul>

      <div className="buttonContainer">
        <NextPageButton
          titleBack={"Zurück"}
          path={"/newchecklist/step3"}
        ></NextPageButton>
        <Link to={`/checklist?checklistId=${checklistId}`}>
          <button className="applyButton">Übernehmen</button>
        </Link>
      </div>
    </div>
  );
}

export default Overview;

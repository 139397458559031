import { useEffect, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { DropdownMenu, RadiobuttonCheckbox } from "../../Components";
import { v4 } from "uuid";
import DayPickerComponent from "../../Components/DayPicker/DayPicker";
import getDailyChecklistArray from "../../utils/dailyChecklistenLocalStorage";
import "./AddNewDailyChecklistPage.css";
import { SlIcon } from "@shoelace-style/shoelace/dist/react";
import { format } from "date-fns";
import subItem from "../../Images/Icons.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddNewDailyChecklistPage({ open, pickedDay }) {
  const [isPopupOpen, setIsPopupOpen] = useState(open);

  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const pickedDay = searchParams.get("pickedDay");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [addItem, setAddItem] = useState("");
  let [checkpointsItem, setCheckpointsItem] = useState([]);

  const [selectedDay, setSelectedDay] = useState(pickedDay);
  const [selectedEndDay, setSelectedEndDay] = useState(new Date());
  console.log("selectedEndDay", selectedEndDay);
  const [repeatValue, setRepeatValue] = useState("never");
  const [finishValue, setFinishValue] = useState("never");
  const [selectedItem, setSelectedItem] = useState();
  const [showSelectedItem, setShowSelectedItem] = useState(false);

  const [showDayPicker, setShowDayPicker] = useState(false);
  const [showEndDayPicker, setShowEndDayPicker] = useState(false);

  const [showSubInputID, setShowSubInputID] = useState(false);
  const [addSubItem, setAddSubItem] = useState("");
  const [showInputField, setShowInputField] = useState(false);
  const formattedSelectedDay = format(new Date(selectedDay), "PP");
  const [undoDeletedItem, setUndoDeletedItem] = useState(null);
  const [deletedItemIndex, setDeletedItemIndex] = useState(null);
  const [toastVisible, setToastVisible] = useState(false);

  const newId = v4();

  const handleSelectedDayChange = (day) => {
    setSelectedDay(day);
  };

  const handleSelectedDayRepeatEnd = (day) => {
    setSelectedEndDay(day);
  };

  let checklists = getDailyChecklistArray();

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    setSelectedItem([]); // Zustand der ausgewählten Checkpunkte zurücksetzen, wenn die Komponente montiert wird
  }, []);

  const handleAddItem = (event) => {
    if (addItem.length !== 0) {
      checkpointsItem.unshift({
        checkpointsId: Math.floor(100 + Math.random() * 900),
        content: addItem.charAt(0).toUpperCase() + addItem.slice(1),
        isChecked: false,
      });

      setAddItem("");
    }
  };

  const handleKeyUp = (event) => {
    if (event.code === "Enter") {
      handleAddItem();
    }
  };

  const handleRepeatOptionChange = (event) => {
    setRepeatValue(event.target.value);
    setSelectedEndDay(new Date(2030, 0, 1));
  };

  const handleFinishRepeatOptionChange = (event) => {
    setFinishValue(event.target.value);
    if (event.target.value === "never") setSelectedEndDay(new Date(2030, 0, 1));
  };

  const generateRepeatedChecklists = (checklist) => {
    const repeatedChecklists = [];
    const { repeats } = checklist;
    const baseId = v4();

    if (repeats === "daily") {
      const currentDate = new Date(selectedDay);
      let endDate;

      if (selectedEndDay > currentDate) {
        endDate = selectedEndDay;

        while (currentDate <= endDate) {
          const id =
            currentDate.toDateString() === selectedDay.toDateString()
              ? baseId + "original"
              : baseId + Math.floor(100 + Math.random() * 900);
          repeatedChecklists.push({
            ...checklist,
            id: id,
            date: currentDate.toDateString(),
            repeatValue: "yes",
          });

          currentDate.setDate(currentDate.getDate() + 1);
        }
      } else {
        endDate = new Date(2030, 0, 1);
        while (currentDate < endDate) {
          const id =
            currentDate.toDateString() === selectedDay.toDateString()
              ? baseId + "original"
              : baseId + Math.floor(100 + Math.random() * 900);
          repeatedChecklists.push({
            ...checklist,
            id: id,
            date: currentDate.toDateString(),
            repeatValue: "yes",
          });

          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
    } else if (repeats === "weekly") {
      const currentDate = new Date(selectedDay);
      let endDate;

      if (selectedEndDay > currentDate) {
        endDate = selectedEndDay;

        while (currentDate <= endDate) {
          const id =
            currentDate.toDateString() === selectedDay.toDateString()
              ? baseId + "original"
              : baseId + Math.floor(100 + Math.random() * 900);
          repeatedChecklists.push({
            ...checklist,
            id: id,
            date: currentDate.toDateString(),
            repeatValue: "yes",
          });

          currentDate.setDate(currentDate.getDate() + 7);
        }
      } else {
        endDate = new Date(2030, 0, 1);
        while (currentDate < endDate) {
          const id =
            currentDate.toDateString() === selectedDay.toDateString()
              ? baseId + "original"
              : baseId + Math.floor(100 + Math.random() * 900);
          repeatedChecklists.push({
            ...checklist,
            id: id,
            date: currentDate.toDateString(),
            repeatValue: "yes",
          });

          currentDate.setDate(currentDate.getDate() + 7);
        }
      }
    } else if (repeats === "everyTwoWeeks") {
      const currentDate = new Date(selectedDay);
      let endDate;

      if (selectedEndDay > currentDate) {
        endDate = selectedEndDay;

        while (currentDate <= endDate) {
          const id =
            currentDate.toDateString() === selectedDay.toDateString()
              ? baseId + "original"
              : baseId + Math.floor(100 + Math.random() * 900);
          repeatedChecklists.push({
            ...checklist,
            id: id,
            date: currentDate.toDateString(),
            repeatValue: "yes",
          });

          currentDate.setDate(currentDate.getDate() + 14);
        }
      } else {
        endDate = new Date(2030, 0, 1);
        while (currentDate < endDate) {
          const id =
            currentDate.toDateString() === selectedDay.toDateString()
              ? baseId + "original"
              : baseId + Math.floor(100 + Math.random() * 900);
          repeatedChecklists.push({
            ...checklist,
            id: id,
            date: currentDate.toDateString(),
            repeatValue: "yes",
          });

          currentDate.setDate(currentDate.getDate() + 14);
        }
      }
    } else if (repeats === "monthly") {
      const currentDate = new Date(selectedDay);
      let endDate;

      if (selectedEndDay > currentDate) {
        endDate = selectedEndDay;

        while (currentDate <= endDate) {
          const id =
            currentDate.toDateString() === selectedDay.toDateString()
              ? baseId + "original"
              : baseId + Math.floor(100 + Math.random() * 900);
          repeatedChecklists.push({
            ...checklist,
            id: id,
            date: currentDate.toDateString(),
            repeatValue: "yes",
          });

          currentDate.setMonth(currentDate.getMonth() + 1);
        }
      } else {
        endDate = new Date(2030, 0, 1);
        while (currentDate < endDate) {
          const id =
            currentDate.toDateString() === selectedDay.toDateString()
              ? baseId + "original"
              : baseId + Math.floor(100 + Math.random() * 900);
          repeatedChecklists.push({
            ...checklist,
            id: id,
            date: currentDate.toDateString(),
            repeatValue: "yes",
          });

          currentDate.setMonth(currentDate.getMonth() + 1);
        }
      }
    } else if (repeats === "yearly") {
      const currentDate = new Date(selectedDay);
      let endDate;

      if (selectedEndDay > currentDate) {
        endDate = selectedEndDay;

        while (currentDate <= endDate) {
          const id =
            currentDate.toDateString() === selectedDay.toDateString()
              ? baseId + "original"
              : baseId + Math.floor(100 + Math.random() * 900);
          repeatedChecklists.push({
            ...checklist,
            id: id,
            date: currentDate.toDateString(),
            repeatValue: "yes",
          });

          currentDate.setFullYear(currentDate.getFullYear() + 1);
        }
      } else {
        endDate = new Date(2030, 0, 1);
        while (currentDate < endDate) {
          const id =
            currentDate.toDateString() === selectedDay.toDateString()
              ? baseId + "original"
              : baseId + Math.floor(100 + Math.random() * 900);
          repeatedChecklists.push({
            ...checklist,
            id: id,
            date: currentDate.toDateString(),
            repeatValue: "yes",
          });

          currentDate.setFullYear(currentDate.getFullYear() + 1);
        }
      }
    }
    return repeatedChecklists;
  };

  const onItemSelected = (item) => {
    setShowSelectedItem(true);
    setSelectedItem(item);
  };

  const handleSaveDailyChecklist = () => {
    if (title.length > 0) {
      const newItem = {
        id: newId,
        title: title,
        description: description,
        date: pickedDay.toDateString(),
        repeatValue: "no",
        repeats: repeatValue,
        checkpoints: checkpointsItem,
      };
      const originalId = newItem.id;

      const link = document.createElement("a");
      link.href = `/dailychecklistpage?checklistId=${originalId}`;
      link.click();
      checklists.unshift(newItem);
      localStorage.setItem("DailyChecklistArray", JSON.stringify(checklists));
    } else {
      alert("Give your Checklist a Title");
    }
  };

  const handleSaveChecklist = () => {
    if (repeatValue === "never") {
      handleSaveDailyChecklist();
    } else {
      const newChecklists = generateRepeatedChecklists({
        id: v4(),
        title: title,
        description: description,
        date: pickedDay,
        repeatValue: "yes",
        repeats: repeatValue,
        checkpoints: checkpointsItem,
      });
      console.log("newChecklists[0]", newChecklists[0]);
      const originalId = newChecklists[0]?.id; // Hole die "original"-ID aus dem ersten Element

      const link = document.createElement("a");
      link.href = `/dailychecklistpage?checklistId=${originalId}`;
      link.click();
      checklists.unshift(...newChecklists);
      localStorage.setItem("DailyChecklistArray", JSON.stringify(checklists));
    }
    setCheckpointsItem(checkpointsItem);
  };

  //Speichert die ausgewählten Checkboxen des Users
  const handleSaveCheckpoint = (event, el, id) => {
    selectedItem[0]?.checkpoints.map((check) => {
      if (check.checkpointsId === event.checkpointsId) {
        if (event.isChecked) {
          const newArr = {
            checkpointsId: Math.floor(100 + Math.random() * 900),
            content: el.content,
            isChecked: false,
          };
          checkpointsItem.unshift(newArr);
          setCheckpointsItem([...checkpointsItem]);
        } else {
          const filterCheckpoints = checkpointsItem.filter(
            (item) => item.content !== el.content
          );
          setCheckpointsItem(filterCheckpoints);
        }
      }
    });
  };

  const handleToodleSubItem = (id) => {
    setShowSubInputID(id);
    setShowInputField(!showInputField);
  };

  const handleAddSubItem = (event, checkpointId) => {
    if (addSubItem.length > 0 && event.code === "Enter") {
      const updatedCheckpointsItem = checkpointsItem.map((checkpoint) => {
        if (checkpoint.checkpointsId === checkpointId) {
          const newSubItem = {
            checkpointsId: Math.floor(100 + Math.random() * 900),
            content: addSubItem.charAt(0).toUpperCase() + addSubItem.slice(1),
            isChecked: false,
          };
          const updatedSubItems = checkpoint.subItem
            ? [newSubItem, ...checkpoint.subItem]
            : [newSubItem];

          return {
            ...checkpoint,
            subItem: updatedSubItems,
          };
        }
        return checkpoint; // Rückgabe des unveränderten Checkpoints
      });

      setCheckpointsItem(updatedCheckpointsItem);
      setAddSubItem("");
    }
  };

  const handleSelectedItem = (event) => {
    setShowSelectedItem(!showSelectedItem);
    if (showSelectedItem === false) {
      console.log("FALSE");
    } else {
      setSelectedItem(0);
    }
  };

  const handleDeleteCheckpoint = (id, content, index) => {
    const deletedCheckpoint = checkpointsItem.find(
      (checkpoint) => checkpoint.checkpointsId === id
    );
    const updatedCheckpoints = checkpointsItem.filter(
      (checkpoint) => checkpoint.checkpointsId !== id
    );
    setCheckpointsItem(updatedCheckpoints);
    setUndoDeletedItem(deletedCheckpoint);
    setDeletedItemIndex(index);
  };

  const handleUndoDelete = () => {
    const updatedCheckpoints = [...checkpointsItem];
    updatedCheckpoints.splice(deletedItemIndex, 0, undoDeletedItem);
    setCheckpointsItem(updatedCheckpoints);
    setUndoDeletedItem(null);
    setDeletedItemIndex(null);
  };

  const handleDeleteSubCheckpoint = (id, duration) => {
    const updatedCheckpoints = checkpointsItem.map((checkpoint) => {
      const updateSubCheckpoints = checkpoint.subItem?.filter(
        (sub) => sub.checkpointsId !== id
      );
      return {
        ...checkpoint,
        subItem: updateSubCheckpoints,
      };
    });
    setCheckpointsItem(updatedCheckpoints);
  };

  useEffect(() => {
    if (undoDeletedItem) {
      setToastVisible(true);
      const timeout = setTimeout(() => {
        setToastVisible(false);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [undoDeletedItem]);

  return (
    <>
      {isPopupOpen && (
        <div className="DailyOverlay">
          <div className="AddNewDailyChecklistPage">
            <div className="close-popup">
              <FaTimes
                className="close-icon"
                onClick={() => setIsPopupOpen(!isPopupOpen)}
              />
            </div>
            <div className="mainContainer">
              <div className="AddNewDailyChecklistPageContainer">
                <h1 className="AddNewDailyChecklistPageTitle">
                  Neue Tagescheckliste
                </h1>
              </div>
              <div className="AddNewDailyChecklistTitleContainer">
                <div className="checklistTitle">
                  <strong>Titel</strong>
                  <label htmlFor="title">
                    <br />

                    <input
                      className="headerInput"
                      id="title"
                      type="text"
                      key="title"
                      placeholder="Wie soll die Checkliste heißen?"
                      value={title}
                      onChange={handleTitleChange}
                    />
                  </label>
                </div>
                <div className="checklistDescription">
                  <label htmlFor="description">
                    <strong>
                      Beschreibung{" "}
                      <span style={{ fontSize: "16px", color: "#86888E" }}>
                        (optional)
                      </span>
                    </strong>
                    <br />
                    <textarea
                      id="description"
                      key="description"
                      placeholder="Beschreibe deine Checkliste in kurzen Worten."
                      rows="4"
                      value={description}
                      onChange={handleDescriptionChange}
                    />
                  </label>
                </div>
              </div>
              <div className="AddNewDailyChecklistDateContainer">
                <div className="checklistTitle">
                  <strong>Datum</strong>
                  <div style={{ margin: "10px 0" }}>
                    <div
                      onClick={() => setShowDayPicker(!showDayPicker)}
                      className="DayPickerComponentBtn"
                    >
                      {formattedSelectedDay}
                    </div>
                    {showDayPicker && (
                      <DayPickerComponent
                        pickedDay={selectedDay}
                        showDayPickerTrue={showDayPicker}
                        onSelectedDayChange={handleSelectedDayChange}
                      />
                    )}
                  </div>
                </div>
                <div className="repeadContainer">
                  <div className="checklistTitle">
                    <strong>
                      Wiederholen{" "}
                      <span style={{ fontSize: "16px", color: "#86888E" }}>
                        (optional)
                      </span>
                    </strong>

                    <div className="RepeatDropdownmenu">
                      <select
                        className="select"
                        onChange={handleRepeatOptionChange}
                        value={repeatValue}
                      >
                        <option value="never">Niemals</option>
                        <option value="daily">Täglich</option>
                        <option value="weekly">Wöchentlich</option>
                        <option value="everyTwoWeeks">Alle 2 Wochen</option>
                        <option value="monthly">Monatlich</option>
                        <option value="yearly">Jährlich</option>
                      </select>
                    </div>
                  </div>
                  {repeatValue !== "never" && (
                    <div>
                      <strong>
                        Ende{" "}
                        <span style={{ fontSize: "16px", color: "#86888E" }}>
                          (optional)
                        </span>
                      </strong>
                      <div className="RepeatDropdownmenu">
                        <select
                          className="select"
                          onChange={handleFinishRepeatOptionChange}
                          value={finishValue}
                        >
                          <option value="never">Niemals</option>
                          <option value="endDate">Enddatum</option>
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="AddNewDailyChecklistPageAddCheckpoints">
                <div className="AddNewDailyChecklistPageAddCheckpointssContainer">
                  {/* <button
                    onClick={handleAddItem}
                    disabled={addItem.trim() === ""}
                    className="iconButton"
                  >
                    <FaPlus
                      style={{ minHeight: "15px", minWidth: "15px" }}
                    ></FaPlus>
                  </button> */}
                  <strong>Tagesaufgaben</strong>
                  <input
                    className="newDailyCheckpointsInput"
                    placeholder="Füge eigene Checkpunkte hinzu"
                    value={addItem}
                    onKeyUp={handleKeyUp}
                    onChange={(e) => setAddItem(e.target.value)}
                  ></input>
                </div>
                <div className="repeadContainer">
                  <div className="checklistTitle">
                    <strong>
                      Vorlage{" "}
                      <span style={{ fontSize: "16px", color: "#86888E" }}>
                        (optional)
                      </span>
                    </strong>
                    <DropdownMenu
                      onItemSelected={onItemSelected}
                      className="TemplateDropdownMenu"
                    ></DropdownMenu>
                  </div>
                </div>
                <div className="pick-end-date-container">
                  {finishValue === "endDate" && repeatValue !== "never" && (
                    <div style={{ margin: "10px 0" }}>
                      <div
                        onClick={() => setShowEndDayPicker(!showEndDayPicker)}
                        className="EndDayPickerComponentBtn"
                      >
                        {format(selectedEndDay, "PP")}
                      </div>
                      {showEndDayPicker && (
                        <DayPickerComponent
                          pickedDay={selectedEndDay}
                          showDayPickerTrue={showEndDayPicker}
                          onSelectedDayChange={handleSelectedDayRepeatEnd}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div>
                {selectedItem?.length > 0 && (
                  <div className="selectedItemContainer">
                    <div>
                      <button
                        onClick={handleSelectedItem}
                        style={{
                          background: "none",
                          border: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <SlIcon
                          name="x"
                          style={{
                            minHeight: "30px",
                            minWidth: "30px",
                            width: "30px",
                            margin: "6px 5px 6px 0",
                            color: "var(--text-color)",
                          }}
                        ></SlIcon>
                        <div className="hideCheckpoints">
                          Checkpunkte verbergen
                        </div>
                      </button>
                    </div>
                    <div className="line"></div>
                    {showSelectedItem === true &&
                      selectedItem[0]?.checkpoints.map((checkpoint, index) => (
                        <RadiobuttonCheckbox
                          key={`${checkpoint.checkpointsId}-${index}`} // Eindeutige Schlüsselprop
                          content={checkpoint.content}
                          checkpointsId={checkpoint.checkpointsId}
                          name={`checkpoint${index}`}
                          onChange={(e) =>
                            handleSaveCheckpoint(e, checkpoint, checkpointsItem)
                          }
                          isChecked={checkpoint?.isChecked || false}
                          value={checkpoint}
                        ></RadiobuttonCheckbox>
                      ))}
                  </div>
                )}
              </div>
              <div className="checkpoints-list-container">
                {checkpointsItem?.map((checkpoint) => {
                  return (
                    <ul
                      key={checkpoint.checkpointsId}
                      className="daily-checkpoints"
                    >
                      <div className="daily-checkpoints-container">
                        <div className="daily-checkpoints-features">
                          {" "}
                          <li
                            style={{ listStyle: "none" }}
                            key={checkpoint.checkpointsId}
                          >
                            {checkpoint.content}
                          </li>
                          <div className="subContentBtn">
                            <div
                              className="subItem"
                              alt="add subitem"
                              style={{
                                margin: "0 10px 0 0",
                                width: "20px",
                                height: "20px",
                              }}
                              onClick={() =>
                                handleToodleSubItem(checkpoint.checkpointsId)
                              }
                            />
                            <SlIcon
                              name="trash"
                              style={{
                                margin: "0 10px 0 0",
                                width: "20px",
                                height: "20px",
                              }}
                              onClick={() =>
                                handleDeleteCheckpoint(
                                  checkpoint.checkpointsId,
                                  checkpoint.content
                                )
                              }
                            ></SlIcon>
                          </div>
                        </div>

                        <div>
                          {showInputField &&
                            showSubInputID === checkpoint.checkpointsId && (
                              <div className="subInputFeld">
                                {/* <FaPlus
                                style={{ minHeight: "10px", minWidth: "10px" }}
                              ></FaPlus> */}
                                <input
                                  placeholder="Füge Unterpunkte hinzu"
                                  className="subInput"
                                  value={addSubItem}
                                  onKeyDown={(e) =>
                                    handleAddSubItem(
                                      e,
                                      checkpoint.checkpointsId
                                    )
                                  }
                                  onChange={(e) =>
                                    setAddSubItem(e.target.value)
                                  }
                                />
                              </div>
                            )}
                        </div>
                        {checkpoint.subItem?.map((sub) => {
                          return (
                            <ul
                              className="AddNewDailyChecklistPageUl"
                              style={{ margin: "0px" }}
                              key={sub.checkpointsId}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <button
                                  style={{
                                    background: "none",
                                    border: "none",
                                    margin: "0",
                                    padding: "0",
                                  }}
                                  onClick={() =>
                                    handleDeleteSubCheckpoint(sub.checkpointsId)
                                  }
                                >
                                  <SlIcon
                                    name="x"
                                    style={{
                                      minHeight: "25px",
                                      minWidth: "25px",
                                      color: "var(--primary-color)",
                                      margin: "0px 0 0 -6px",
                                    }}
                                  ></SlIcon>
                                </button>
                                <li
                                  style={{
                                    listStyle: "none",
                                  }}
                                  key={sub.checkpointsId}
                                >
                                  {sub.content}
                                </li>
                              </div>
                            </ul>
                          );
                        })}
                      </div>
                    </ul>
                  );
                })}
              </div>

              <div className="AddNewDailyChecklistPageButton">
                <button
                  className="cancelButton"
                  onClick={() => setIsPopupOpen(!isPopupOpen)}
                >
                  Abbrechen
                </button>
                {title.length > 0 ? (
                  <button className="applyButton" onClick={handleSaveChecklist}>
                    Erstellen
                  </button>
                ) : (
                  <button
                    className="applyButtonDisabled"
                    onClick={handleSaveChecklist}
                    style={{ color: "gray" }}
                  >
                    Erstellen
                  </button>
                )}
              </div>
            </div>
            {undoDeletedItem && toastVisible && (
              <div className="toast-container">
                <div className="toast-message">
                  Checkpunkt "{undoDeletedItem.content}" gelöscht.
                </div>
                <button
                  className="toast-undo-button"
                  onClick={handleUndoDelete}
                >
                  <p style={{ textDecoration: "underline" }}>
                    Aktion Rückgängig machen
                  </p>
                </button>
                <div className="toast-circle">
                  <svg width="20" height="20">
                    <circle
                      cx="10"
                      cy="10"
                      r="8"
                      className="toast-circle-path"
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default AddNewDailyChecklistPage;

import "./NewChecklist.css";
import {
  Route,
  Link,
  useNavigate,
  Routes,
  useLocation,
} from "react-router-dom";
import StepIndicator from "../../Components/StepIndicator";
import CreateChecklist from "../CreateChecklist/CreateChecklist";
import AddNewCheckpoints from "../AddNewCheckpoints/AddNewCheckpoints";
import { useState } from "react";
import ChooseNewTemplate from "../ChooseNewTemplate/ChooseNewTemplate";
import Overview from "../Overview/Overview";

function NewChecklist(props) {
  const location = useLocation();

  function showstep(step) {
    switch (step) {
      case 1:
        return <CreateChecklist />;
      case 2:
        return <ChooseNewTemplate />;
      case 3:
        return <AddNewCheckpoints />;
      case 4:
        return <Overview />;
    }
  }

  return (
    <div className="newchecklist">
      <div className="mainContainer">
        <StepIndicator className="stepIndicator" />

        <div className="output">
          {location.pathname === "/newchecklist/step1" && showstep(1)}
          {location.pathname === "/newchecklist/step2" && showstep(2)}
          {location.pathname === "/newchecklist/step3" && showstep(3)}
          {location.pathname === "/newchecklist/step4" && showstep(4)}
        </div>
      </div>
    </div>
  );
}

export default NewChecklist;
